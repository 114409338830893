import React from 'react';
import Modal from '../generic/component/modal/Modal';
import Injector from '../core/utils/Injector';
import PropTypes from 'prop-types';

const FatalErrorModal = ({
  title = 'An error occurred',
  className = '',
}) => (
  <Modal className={className} title={title} isOpen>
    <p style={{marginTop: '8px'}}>
      You may wish to <a href={'#'} onClick={() => location.reload()}>
        <u>try again</u></a> or <a href={'mailto:' + Injector.config('support_email')}>
        <u>contact support</u></a>.
    </p>
  </Modal>
);

FatalErrorModal.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default FatalErrorModal;
