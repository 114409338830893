import PropTypes from 'prop-types';
import React, {useEffect, useState, useRef} from 'react';
import {AlertTriangle} from 'react-feather';
import ReactModal from 'react-modal';

import styles from './styles.scss';

const Modal = ({
  children,
  icon,
  title,
  size, // Size of the modal
  scrollableOverlay = false, // Instead of scrolling the modal, scrolls the white overlay behind the modal
  priority = 'low', // Modal priorities, available low, normal, high.
  isOpen,
  ...restProps
}) => {
  // Filter out correct sizes
  size = ['small', 'medium', 'large', 'fitwindow', 'fullscreen'].find((v) => v === String(size).toLowerCase());

  // Modal priorities
  const priorities = {
    low: 'p-low',
    normal: 'p-normal',
    high: 'p-high',
  };

  // Check if the modal has rendered and if we should full screen
  const modalRef = useRef(null);
  const [fullScreen, updateFullScreen] = useState(false); // Please do not set directly with setter, use function below/

  const setFullScreen = (opened, element) => {
    if (opened && element) {
      // Update state
      updateFullScreen(true);

      // Full screen modal wrapper
      element?.requestFullscreen()?.catch((error) => {
        console.warn(`Could not open view in full screen, error: ${error}`);
      });
    } else if (!opened) {
      // Update state
      updateFullScreen(false);

      // Remove full screen
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    // Should we make it fullscreen?
    if (size !== 'fullscreen') return;

    // Check if we can full screen modal
    let tries = 0;
    const checkLoop = setInterval(() => {
      // How many tries have we done?
      tries++;
      if (tries>10) clearInterval(checkLoop);

      // Try getting the element
      const modalFullScreen = document.getElementById('fullscreen-modal');

      // Can we run fullscreen now?
      if (modalFullScreen && isOpen) {
        // We're here! Clear interval
        clearInterval(checkLoop);

        // Set fullscreen
        setFullScreen(true, modalFullScreen);

        // Debugging
        console.log(`Running fullscreen: ${fullScreen}`);
      }
    }, 500);
  }, [isOpen, modalRef.current]);

  // Modal content
  const content = (
    <>
      {(title.length > 0) && (
        <h2 className={styles.title}>
          {icon}
          {title}
        </h2>
      )}
      <div className={styles.message}>
        {children}
      </div>
    </>
  );

  return (<ReactModal
    {...{...restProps, isOpen}}
    icon={icon}
    ref={modalRef}
    className={`
      ${styles.modal}
      ${size ? styles[size] : ''} 
      ${scrollableOverlay ? styles['y-scrollable'] : ''} 
    `}
    overlayClassName={`${styles.overlay} ${styles[priorities[String(priority).toLowerCase() || 'low']]}`}
  >
    {
      size === 'fullscreen' && <div id="fullscreen-modal">
        {content}
      </div>
    }
    {
      size !== 'fullscreen' && content
    }
  </ReactModal>);
};

Modal.defaultProps = {
  icon: <AlertTriangle/>,
  title: '',
};

Modal.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string,
  icon: PropTypes.element,
  size: PropTypes.string,
  scrollableOverlay: PropTypes.bool,
  priority: PropTypes.string,
  isOpen: PropTypes.bool,
};

export default React.memo(Modal);
