import {RSAA} from 'redux-api-middleware';
import Injector from '../../core/utils/Injector';
import store from '../../redux/store';
import {getAssetAccess} from '../actions/userActions';

export function dispatchStratumApiCall(
    dispatch,
    name,
    method,
    action,
    body,
    meta,
) {
  const stratum = store.getState().user.stratum;
  let uri = Injector.config('stratum_api_endpoint_override');

  if (!uri) {
    uri = stratum.uri;
  }
  return dispatchApiCall( dispatch, name, uri + action, method, body, [], {}, meta);
}

function dispatchApiCall(
    dispatch,
    name,
    endpoint,
    method,
    body,
    overrideTypes = [],
    headers = {},
    meta = {},
    attemptReauthOnFailure = true,
) {
  const action = () => generateApiCall(
      name,
      endpoint,
      method,
      body,
      overrideTypes,
      headers,
      meta,
  );

  if (typeof dispatch !== 'function') {
    console.log('Not a function!', dispatch);
    return;
  }

  const handleResponse = (response) => {
    if (response.payload?.name && ['ApiError', 'RequestError'].includes(response.payload.name)) {
      return Promise.reject(response);
    }

    return Promise.resolve(response);
  };

  const tryRefreshing = (response) => {
    // if we've had something other than a 401, no point refreshing
    if (!attemptReauthOnFailure || !(response.payload?.status) || response.payload.status !== 401) {
      return Promise.reject(response);
    }

    return dispatch(getAssetAccess());
  };

  const ohWellWeTried = (response) => {
    console.log('Stratum API call failed', response);
    dispatch({type: `${name}_FAILURE`, payload: response.payload});
  };

  return dispatch(action()).then(handleResponse).catch(
      (response) => {
        return tryRefreshing(response)
            .then(() => dispatch(action()))
            .then((response) => handleResponse(response))
            .catch((response) => ohWellWeTried(response));
      },
  );
}


function generateApiCall(
    name,
    endpoint,
    method,
    body,
    overrideTypes = [],
    headers = {},
    meta = {},
) {
  const types = Array(3);
  const typeNames = [`${name}_REQUEST`, `${name}_COMPLETE`, `${name}_UNSUCCESSFUL`];
  for (let i = 0; i < 3; i++) {
    types[i] = (!overrideTypes[i]) ?
        typeNames[i] :
        Object.assign({}, overrideTypes[i], {type: typeNames[i]});

    if (meta) {
      types[i] = {
        type: types[i],
        meta: meta,
      };
    }
  }

  const stratum = store.getState().user.stratum;

  const h = Object.assign({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer '+stratum.token,
  }, headers);

  if (body!==null) {
    body = JSON.stringify(body);
  }

  return {
    [RSAA]: {
      endpoint,
      method,
      headers: h,
      body: body,
      credentials: 'include',
      types,
    },
  };
}
