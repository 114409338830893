const GET_ASSET_LINES_COMPLETE = 'GET_ASSET_LINES_COMPLETE';
const GET_ASSET_LINES_FAILURE = 'GET_ASSET_LINES_FAILURE';
const GET_ASSET_LINES_REQUEST = 'GET_ASSET_LINES_REQUEST';

export default function reducer(state = {
  isFetching: false,
  fetchComplete: false,
  options: null,
  fetchError: null,
  searchTerm: '',
}, action) {
  switch (action.type) {
    case 'Asset filter line search term change':
      if (action.payload.term == state.searchTerm) {
        return state; // no change!
      }
      return {
        ...state,
        searchTerm: action.payload.term,
        fetchComplete: false,
        isFetching: false,
      };
    case 'Asset filter line search options':
      return {
        ...state,
        fetchComplete: true,
        isFetching: false,
        options: action.payload.options,
      };
    case GET_ASSET_LINES_REQUEST:
      return {
        ...state,
        fetchError: null,
        isFetching: true,
      };
    case GET_ASSET_LINES_FAILURE:
      return {
        ...state,
        fetchError: action.payload,
        isFetching: false,
      };
    case GET_ASSET_LINES_COMPLETE:
      return {
        ...state,
        fetchComplete: true,
        isFetching: false,
        options: itemsTransformer(action),
      };
    default:
      return state;
  }
}

function itemTransformer(item) {
  return {
    label: item.item_reference,
    value: item.id,
  };
}

function itemsTransformer(action) {
  return action.payload.worksorderlines.map(itemTransformer);
}
