import {info, success, error, removeAll as rmAll} from 'react-notification-system-redux';

function notify(action, message, title = null, autoDismiss = 5, feedback) {
  const options = {
    message,
    autoDismiss,
    ...(feedback ? {action: feedback} : {}),
  };

  if (title !== null) {
    options['title'] = title;
  }

  return action(options);
}

export function notifySuccess(message, title = null, autoDismiss) {
  return notify(success, message, title, autoDismiss);
}

export function notifyInfo(message, title = null, autoDismiss, feedback) {
  return notify(info, message, title, autoDismiss, feedback);
}

export function notifyError(message, title = null, autoDismiss) {
  return notify(error, message, title, autoDismiss);
}

export const removeAll = () => rmAll();
