import React from 'react';
import styles from './style.scss';
import PropTypes from 'prop-types';

const Loading = ({text = null}) => {
  return (
    <div className={styles.fetchOverlay}>
      <div className={styles.content}>
        <div className={styles.spinner}/>
        {text && <div className={styles.text}>{text}</div>}
      </div>
    </div>
  );
};

Loading.propTypes = {
  text: PropTypes.string,
};

export default React.memo(Loading);
