const defaultPayloadTransformer = ({payload}) => payload;

export default function createAsyncReducer(args = {}) {
  const {
    dataKey = 'data',
    payloadTransformer = defaultPayloadTransformer,
    typeComplete,
    typeFailure,
    typeRequest,
  } = args;

  const initialState = {
    [dataKey]: null,
    fetchComplete: false,
    fetchError: null,
    isFetching: false,
  };

  return (state = initialState, action) => {
    switch (action.type) {
      case typeRequest:
        return {
          ...state,
          fetchComplete: false,
          fetchError: null,
          isFetching: true,
        };
      case typeComplete:
        return {
          ...state,
          [dataKey]: payloadTransformer(action),
          fetchComplete: true,
          isFetching: false,
        };
      case typeFailure:
        return {
          ...state,
          fetchComplete: true,
          fetchError: action.payload,
          isFetching: false,
        };
      default:
        return state;
    }
  };
}
