import React from 'react';
import {hot} from 'react-hot-loader';
import {IntlProvider} from 'react-intl';

import Announcements from './Announcements';

import Router from './Router';

import '../scss/global.scss';

const App = () => (
  <IntlProvider locale="en">
    <Announcements />
    <Router />
  </IntlProvider>
);

export default hot(module)(App);
