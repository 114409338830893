import Injector from '../core/utils/Injector';
import store from '../redux/store';
import {get} from 'lodash';

export function appendQueryParams(url, params = {}) {
  if (url==null) {
    throw new Error('null url passed to appendQueryParams');
  }
  if (params==null) {
    return;
  }
  for (const key in params) {
    if (Object.hasOwnProperty.call(params, key) && params[key]) {
      url.searchParams.append(key, params[key]);
    }
  }
}

export function getPPApiUrl(path) {
  return getApiUrl(Injector.config('pp_api_endpoint'), path);
}

export function getStratumApiUrl(path) {
  const base = Injector.config('stratum_api_endpoint_override') ||
    get(store.getState(), 'user.stratum.uri');

  if (typeof base !== 'string') {
    return null;
  }

  return getApiUrl(base, path);
}

function getApiUrl(base, path) {
  const url = [base, path]
      .map((item) => item.replace(/^\/+|\/+$/g, ''))
      .join('/');

  return new URL(url);
}

export class HttpRequest {
  name = null;
  resolve = null;
  reject = null;
  promise = null;
  url = null;
  method = null;
  headers = {};
  body = '';

  constructor(
      url,
      method,
      body,
      headers,
  ) {
    this.url = url;
    this.method = method;
    if (method=='GET') {
      body = '';
    }
    if (typeof(body)!='string') {
      throw new Error('body must be a string');
    }
    this.body = body;
    if (!(headers instanceof Object)) {
      this.headers = {};
    } else {
      this.headers = headers;
    }
    this.headers['content-length'] = body.length;

    const _this = this;
    this.promise = new Promise(function(resolve, reject) {
      _this.resolve = resolve;
      _this.reject = reject;
    });
  }

  toString() {
    let string = '';
    string+=this.method.toUpperCase()+' '+this.url+' HTTP/1.1\n';

    for (const key in this.headers) {
      if (Object.prototype.hasOwnProperty.call(this.headers, key)) {
        string+=key+': '+this.headers[key]+'\n';
      }
    }
    string+='\n';
    string+=this.body;
    string+='\n';
    return string;
  }
}
