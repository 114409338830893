import deepEql from 'deep-eql';
import {ApiError} from 'redux-api-middleware';

import {getApprovals, searchApprovals} from './approvalActions';
import {getContactTypes} from './contactTypeActions';
import {getLogEntryUsers as getAllUsers} from './logActions';
import {dispatchPPApiCall} from '../util/apiCallGenerator';
import {getAllProjects} from './projectActions';

export function getFilters() {
  return (dispatch, getState) => {
    const {filters} = getState().filters.approval;
    const fetcher = {
      'approver': getApprovers,
      'client': getClients,
      'status': getStatuses,
      'category': getCategories,
      'project': getAllProjects,
    };

    const display = getState().user.self.displayFilters.map((filter) => {
      return filter.value;
    });

    return dispatch({type: 'Filter fetch start'}).then(() => (Promise.all(
        ['approver', 'client', 'status', 'category', 'project'].filter((filter) => (
          filter === 'category' || (display.includes(filter) &&
                !(filters[filter].fetchComplete || filters[filter].isFetching))
        )).map((filter) => (dispatch(fetcher[filter]()))).map((p) => (
          p.catch((e) => e)
        )),
    ))).then((e) => {
      let type = 'Filter fetch complete';
      if (e.some((response) => response.payload instanceof ApiError)) {
        type += ' with errors';
      }

      dispatch({type});
    });
  };
}

export function setFilters(filters, replace = false) {
  return (dispatch, getState) => {
    const fetchApprovals = () => {
      const isSearching = getState().approval.search.search.length;
      return Promise.all([
        (isSearching) ? dispatch(searchApprovals(1)) : Promise.resolve(),
        dispatch(getApprovals(1, true)),
      ]);
    };

    if (deepEql(getState().filters.approval.current, filters)) {
      return fetchApprovals();
    }

    return dispatch({
      type: (replace) ? 'Replace filter' : 'Change filter',
      payload: filters,
    }).then(fetchApprovals);
  };
}

export function getStatuses(withApprovals = 1, actionName = 'GET_STATUSES') {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        actionName,
        'GET', `/approval/statuses?withApprovals=${withApprovals}`,
    );
  };
}

export function getCategories() {
  return (dispatch, getState) => {
    const {category} = getState().filters.approval.filters;
    return Promise.all(
        [0, 1, 2].filter((depth) => (
          !(category.fetchComplete[depth] || category.isFetching[depth])
        )).map((depth) => (dispatch(getCategoriesForDepth(depth)))),
    );
  };
}

export function getCategoriesForDepth(depth) {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        `GET_CATEGORIES_DEPTH_${depth}`,
        'GET', `/categories?depth=${depth}`,
    );
  };
}

export function getClients() {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'GET_CLIENTS',
        'GET', '/clients?withApprovals=1',
    );
  };
}

export function getApprovers() {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'GET_APPROVERS',
        'GET', '/users?withApprovals=1',
    );
  };
}

export const GET_CONTACT_TYPES_FILTER_COMPLETE = 'GET_CONTACT_TYPES_FILTER_COMPLETE';
export const GET_CONTACT_TYPES_FILTER_FAILURE = 'GET_CONTACT_TYPES_FILTER_FAILURE';
export const GET_CONTACT_TYPES_FILTER_REQUEST = 'GET_CONTACT_TYPES_FILTER_REQUEST';

export function getContactTypesFilter() {
  return (dispatch) => {
    dispatch({
      type: GET_CONTACT_TYPES_FILTER_REQUEST,
    })
        .then(() => getContactTypes()(dispatch))
        .then(({payload}) => {
          dispatch({
            payload: payload.map(({id, name}) => ({
              label: name,
              value: id,
            })),
            type: GET_CONTACT_TYPES_FILTER_COMPLETE,
          });
        })
        .catch(() => dispatch({
          type: GET_CONTACT_TYPES_FILTER_FAILURE,
        }));
  };
}

export const GET_USERS_FILTER_COMPLETE = 'GET_USERS_FILTER_COMPLETE';
export const GET_USERS_FILTER_FAILURE = 'GET_USERS_FILTER_FAILURE';
export const GET_USERS_FILTER_REQUEST = 'GET_USERS_FILTER_REQUEST';

export function getUsersFilter() {
  return (dispatch) => {
    dispatch({
      type: GET_USERS_FILTER_REQUEST,
    })
        .then(() => getAllUsers()(dispatch))
        .then(({payload}) => dispatch({
          payload: payload.map(({displayName, id}) => ({
            label: displayName,
            value: id,
          })),
          type: GET_USERS_FILTER_COMPLETE,
        }))
        .catch(() => dispatch({
          type: GET_USERS_FILTER_FAILURE,
        }));
  };
}
