import {dispatchPPApiCall} from '../util/apiCallGenerator';
import getPage from '../util/getPage';
import {getFilterUrl} from '../../util/Filter';

export function getAllProjects() {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'GET_ALL_PROJECTS',
        'GET', '/projects?all=1',
    );
  };
}

export function getProjects(pageNumber = 1, refetch = false) {
  return (dispatch, getState) => {
    const {pages, pageSize} = getState().projects;
    const current = getState().filters.project.current;

    return getPage(
        dispatch,
        'GET_PROJECTS',
        'GET', '/projects',
        pages, pageSize, pageNumber, getFilterUrl(current).join('&'), {}, refetch,
    );
  };
}

export function updateProjectStatus(status) {
  return {
    type: 'UPDATE_PROJECT_STATUS',
    payload: {
      id: status.toUpperCase(),
      name: status.toLowerCase().replace(/./, (letter) => letter.toUpperCase()),
    },
  };
}

export function searchProjects(pageNumber = 1, refetch = false) {
  return (dispatch, getState) => {
    const {search, searchPages, searchPageSize} = getState().projects;
    const current = getState().filters.project.current;

    return getPage(
        dispatch,
        'GET_PROJECTS_SEARCH',
        'GET', '/projects/_/search',
        searchPages, searchPageSize, pageNumber,
        `search=${search}&${getFilterUrl(current).join('&')}`, {term: search}, refetch,
    );
  };
}

export function getProject(projectId) {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'GET_PROJECT',
        'GET', `/projects/${projectId}`,
        undefined,
        Array(3).fill({meta: {projectId}}),
    );
  };
}

export function setCurrentProject(project) {
  return (dispatch) => dispatch({
    type: 'SET_CURRENT_PROJECT',
    payload: project,
  });
}

export function getPageForProject(project) {
  return (dispatch, getState) => {
    return dispatchPPApiCall(
        dispatch,
        'GET_PROJECTS',
        'GET',
        `/projects?size=${getState().projects.pageSize}&project=${project}`,
        undefined, Array(3).fill({meta: {project, filters: ''}}),
    );
  };
}

export function createProject(project) {
  return (dispatch) => dispatchPPApiCall(
      dispatch,
      'CREATE_PROJECT',
      'POST',
      '/projects',
      project,
  );
}

export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_COMPLETE = 'UPDATE_PROJECT_COMPLETE';
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE';
export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';

export function updateProject(projectId, data) {
  return (dispatch) => dispatchPPApiCall(
      dispatch,
      UPDATE_PROJECT,
      'PATCH',
      `/projects/${projectId}`,
      data,
  );
}
