import {isObject} from 'lodash';
import moment from 'moment';

import {doPPAuthenticatedApiCall} from './Util';

export function validateFilterSelection(query, validOptions, ignore = []) {
  const hasInvalidKeys = Object.keys(query)
      .filter((key) => !ignore.includes(key))
      .some((key) => {
        const keyIsInvalid = !(key in validOptions);
        if (keyIsInvalid) {
          console.error(
              `Key "${key}" is not valid, valid keys are`,
              Object.keys(validOptions),
          );
        }
        return keyIsInvalid;
      });

  if (hasInvalidKeys) {
    return false;
  }

  const validOptionsNormalised = {};
  Object.keys(validOptions)
      .filter((key) => !ignore.includes(key))
      .forEach((key) => {
        validOptionsNormalised[key] = Array.isArray(validOptions[key]) ?
          validOptions[key].map((opt) => {
            return opt.value.toString().toLowerCase();
          }) :
          [];
      });

  const hasInvalidOptions = Object.keys(validOptions)
      .filter((key) => !ignore.includes(key))
      .some((key) => {
        if (!(key in query)) {
          return false;
        }

        const userSelection = query[key].split(',').map((option) => {
          return option.toString().toLowerCase();
        });
        const validOptsForThisKey = validOptionsNormalised[key];

        return userSelection.some((option) => {
          if ((validOptsForThisKey.indexOf(option) < 0)) {
            console.error(option, '(', typeof option, ')',
                ' is not a valid option for ', key,
                ' valid options are ', validOptsForThisKey);
          }
          return (validOptsForThisKey.indexOf(option) < 0);
        });
      });

  return !hasInvalidOptions;
}

export function validateDateFilters(query) {
  return Object.values(query).every((dateFilter) => (
    moment(dateFilter, ['YYYY-MM-DD']).isValid()
  ));
}


export function getFilterUrl(filters) {
  return Object.keys(filters)
      .filter((key) => {
        const filter = filters[key];

        if (Array.isArray(filter)) {
          return filter.length > 0;
        } else if (isObject(filter)) {
          return Object.prototype.hasOwnProperty.call(filter, 'value') || moment.isMoment(filter);
        } else if (typeof filter === 'boolean') {
          return filter;
        }

        return filter !== null && filter !== undefined;
      })
      .map((key) => {
        const filter = filters[key];

        if (Array.isArray(filter)) {
          let value = filter.map((value) => (typeof value === 'object' ? value.value : value)).join(',');
          value = encodeURIComponent(value);
          return `${key}=${value}`;
        } else if (moment.isMoment(filter)) {
          return `${key}=${filter.format('YYYY-MM-DD')}`;
        } else if (isObject(filter)) {
          return `${key}=${filter.value}`;
        } else if (typeof filter === 'boolean') {
          return `${key}=1`;
        }

        const encodedFilter = encodeURIComponent(filter);
        return `${key}=${encodedFilter}`;
      });
}

export function fetchLineOptions(ids) {
  const promises = [];

  for (let x=0; x<ids.length; x++) {
    promises.push(
        doPPAuthenticatedApiCall(
            '/worksorderlines?size=1&line='+ids[x],
            'GET')
            .then((response)=>(response.json())),
    );
  }

  return Promise.all(promises)
      .then(function(values) {
        const options = [];
        values.forEach(function(response) {
          response.worksorderlines.forEach(function(line) {
            options.push({
              label: line.item_reference,
              value: line.id,
            });
          });
        });
        return {
          options: options,
        };
      });
}

export function fetchOrderOptions(ids) {
  const promises = [];

  for (let x=0; x<ids.length; x++) {
    promises.push(
        doPPAuthenticatedApiCall(
            '/worksorders?size=1&order='+ids[x],
            'GET')
            .then((response)=>(response.json())),
    );
  }

  return Promise.all(promises)
      .then(function(values) {
        const options = [];
        values.forEach(function(response) {
          response.worksorders.forEach(function(order) {
            options.push({
              label: order.works_order_code,
              value: order.id,
            });
          });
        });
        return {
          options: options,
        };
      });
}
