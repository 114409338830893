import config from './configReducer';
import current from './currentFilterReducer';
import simple from './simpleReducer';
import createFilterReducer, {createCategoryFilterReducer} from '../../../util/filterReducerGenerator';
import {combineReducers} from 'redux';
import {addPartnerIdItemTransformer} from '../../../../util/partnerId';

const filters = combineReducers({
  category: createCategoryFilterReducer('GET_CATEGORIES'),
  status: createFilterReducer({
    name: 'GET_STATUSES',
    itemTransformer: (item) => ({
      value: item.id,
      label: item.name,
      usercentricLabel: item.usercentricName,
      count: item.count,
    }),
  }),
  client: createFilterReducer({name: 'GET_CLIENTS'}),
  approver: createFilterReducer({name: 'GET_APPROVERS'}),
  project: createFilterReducer(addPartnerIdItemTransformer({
    name: 'GET_ALL_PROJECTS',
  }, (project) => (project.name))),
});

export default combineReducers({
  filters, current, config, simple,
});
