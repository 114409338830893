export default function reducer(state = {
  client: [],
  keywords: [],
  project: [],
  order: [],
  line: [],
  assetMime: [],
  user: [],
  status: [],
}, action) {
  switch (action.type) {
    case 'Change asset filter':
      return {...state, ...action.payload};
    case 'Replace asset filter':
      return {
        client: [],
        keywords: [],
        project: [],
        status: [],
        assetMime: [],
        order: [],
        line: [],
        user: [],
        ...action.payload,
      };
    default:
      return state;
  }
}
