import email from './emailReducer';
import client from './clientReducer';
import group from './groupReducer';
import status from './statusReducer';

import {combineReducers} from 'redux';

export default combineReducers({
  email, client, group, status,
});
