import current from './currentReducer';
import keywords from './keywordsReducer';
import status from './statusReducer';
import assetMime from './assetMimeReducer';
import line from './lineReducer';
import order from './orderReducer';
import user from './userReducer';
import createFilterReducer from '../../../util/filterReducerGenerator';
import {combineReducers} from 'redux';
import {addPartnerIdItemTransformer} from '../../../../util/partnerId';

const uploadTypesItemTransformer = ({id, name}) => ({
  label: name,
  value: id,
});

const uploadTypesItemsTransformer = (itemTransformer, {payload}) => {
  return payload && Array.isArray(payload.entities) ? payload.entities.map(itemTransformer) : [];
};

const filters = combineReducers({
  client: createFilterReducer({name: 'GET_ASSET_CLIENTS'}),
  uploadTypes: createFilterReducer({
    name: 'GET_ASSET_UPLOAD_TYPES',
    itemTransformer: uploadTypesItemTransformer,
    itemsTransformer: uploadTypesItemsTransformer,
  }),
  project: createFilterReducer(addPartnerIdItemTransformer({
    name: 'GET_ALL_PROJECTS',
  }, (project) => (project.name))),
  keywords,
  line,
  order,
  assetMime,
  user,
});


export default combineReducers({
  filters, current, status,
});
