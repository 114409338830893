import {dispatchPPApiCall} from '../util/apiCallGenerator';
import moment from 'moment';

export function createWorkflow(workflow) {
  const {amendedDurations, templateId, worksorderline, skip} = workflow;
  const tasks = Object.keys(amendedDurations);

  const data = {
    'durations': tasks.map((name) => {
      return {
        name: name,
        duration: amendedDurations[name],
      };
    }),
    'skip': skip,
    'templateId': templateId,
    'worksorderline': worksorderline,
  };


  return (dispatch) => dispatchPPApiCall(
      dispatch,
      'CREATE_WORKFLOW',
      'POST',
      '/workflows',
      data,
  );
}

export function updateWorkflows(data) {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'UPDATE_WORKFLOWS',
        'PATCH', '/workflows',
        data,
    );
  };
}

export function getWorkflowTemplate(workflowTemplateId, amendedDurations, projectId) {
  if (typeof(amendedDurations) === 'object' &&
        projectId!==null) {
    const tasks = Object.keys(amendedDurations);

    const data = {
      'amendedDurations': tasks.map((reference) => {
        return {
          reference: reference,
          duration: amendedDurations[reference],
        };
      }),
      'projectId': projectId,
    };

    return (dispatch) => dispatchPPApiCall(
        dispatch,
        'GET_WORKFLOW_TEMPLATE',
        'POST',
        `/workflowtemplates/${workflowTemplateId}`,
        data,
    );
  }

  return (dispatch) => dispatchPPApiCall(
      dispatch,
      'GET_WORKFLOW_TEMPLATE',
      'GET',
      `/workflowtemplates/${workflowTemplateId}`,
  );
}

export const GET_ALL_WORKFLOWS_GROUPED_BY_TEMPLATE = 'GET_ALL_WORKFLOWS_GROUPED_BY_TEMPLATE';

export function getAllWorkflowsGroupedByTemplate() {
  return (dispatch) => dispatchPPApiCall(
      dispatch,
      GET_ALL_WORKFLOWS_GROUPED_BY_TEMPLATE,
      'GET',
      '/workflows?groupByTemplate=1&orderByTemplateName=ASC',
  );
}

export function getClientWorkflowTemplates(clientId, targetEndDate) {
  let url = `/workflowtemplates?client=${clientId}`;

  if (targetEndDate!==null &&
      moment.isMoment(targetEndDate)) {
    url+='&targetEndDate='+targetEndDate.format('YYYY-MM-DD');
  }

  return (dispatch) => dispatchPPApiCall(
      dispatch,
      'GET_WORKFLOW_TEMPLATES',
      'GET',
      url,
  );
}

export function getProjectWorkflowTemplates(projectId) {
  return (dispatch) => dispatchPPApiCall(
      dispatch,
      'GET_WORKFLOW_TEMPLATES',
      'GET',
      `/workflowtemplates?project=${projectId}`,
  );
}
