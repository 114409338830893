/* eslint-disable no-case-declarations */
export default function reducer(state = {
  isFetching: false,
  fetchComplete: false,
  fetchError: null,
  preferences: [],
  selected: [],
  updateComplete: false,
}, action) {
  switch (action.type) {
    case 'GET_EMAIL_PREFERENCES_REQUEST':
      return {
        ...state,
        isFetching: true,
        fetchError: null,
      };

    case 'GET_EMAIL_PREFERENCES_FAILURE':
      return {
        ...state,
        isFetching: false,
        fetchComplete: false,
        fetchError: action.payload,
      };

    case 'GET_EMAIL_PREFERENCES_COMPLETE':
      return {
        ...state,
        isFetching: false,
        fetchComplete: true,
        preferences: action.payload,
      };

    case 'GET_SELF_COMPLETE':
      if (!('preferences' in action.payload)) {
        return state;
      }

      const currentPrefs = action.payload.preferences.filter((pref) => (
        'preference' in pref &&
          'group' in pref.preference &&
          'id' in pref.preference.group &&
          pref.preference.group.id === 'EMAIL'
      )).map((pref) => ({
        id: pref.preference.id,
        options: pref.options.map((option) => ({
          value: option.id,
          label: option.name,
        })),
      }));

      return {
        ...state,
        selected: currentPrefs,
      };

    case 'UPDATE_PREFERENCES_REQUEST':
      return {
        ...state,
        updateComplete: false,
      };

    case 'UPDATE_PREFERENCES_COMPLETE':
      const newPrefs = action.payload.filter((pref) => (
        'preference' in pref &&
          'group' in pref.preference &&
          'id' in pref.preference.group &&
          pref.preference.group.id === 'EMAIL'
      )).map((pref) => ({
        id: pref.preference.id,
        options: pref.options.map((option) => ({
          value: option.id,
          label: option.name,
        })),
      }));

      return {
        ...state,
        selected: newPrefs,
        updateComplete: true,
      };

    case 'Update selected email preferences':
      const selected = state.selected.filter((pref) => {
        return (pref.id !== action.payload.id);
      });

      selected.push({
        id: action.payload.id,
        options: action.payload.value,
      });

      return {
        ...state,
        selected,
      };

    default:
      return state;
  }
}
