export default function reducer(state = {
  client: [],
  category0: [],
  category1: [],
  category2: [],
  late: false,
  project: [],
  status: [],
}, action) {
  switch (action.type) {
    case 'Change line filter':
      return {...state, ...action.payload};
    case 'Replace line filter':
      return {
        client: [],
        category0: [],
        category1: [],
        category2: [],
        late: false,
        project: [],
        status: [],
        ...action.payload,
      };
    default:
      return state;
  }
}
