import Injector from '../core/utils/Injector';
import store from '../redux/store';

export function normalizeAsset(asset) {
  const ret = {
    id: asset.id,
    height: null,
    width: null,
    bytes: null,
    date: null,
    projects: [],
    orders: [],
    orderlines: [],
    thumb: '',
    preview: '',
    download: false,
    delete: false,
    name: '',
    // Update 15/09/2021 - MHG-2728
    uploadTypes: [],
  };

  if (asset.permissions &&
        asset.permissions.download &&
        asset.permissions.download === true) {
    ret.download = function() {
      const stratum = store.getState().user.stratum;
      let uri = Injector.config('stratum_api_endpoint_override');
      if (!uri) {
        uri = stratum.uri;
      }
      uri+='core/asset/assets/'+asset.id+'/versions/latest/downloads';
      fetch(uri, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer '+stratum.token,
        },
      })
          .then((res) => res.json())
          .then(function(result) {
            if (result.downloadPresignedUrl) {
              location.href = result.downloadPresignedUrl;
            }
          });
    };
  }

  if (asset.permissions &&
        asset.permissions.delete &&
        asset.permissions.delete === true) {
    ret.delete = function() {
      const stratum = store.getState().user.stratum;
      let uri = Injector.config('stratum_api_endpoint_override');
      if (!uri) {
        uri = stratum.uri;
      }
      uri+='core/asset/assets/'+asset.id;
      return fetch(uri, {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer '+stratum.token,
        },
      });
    };
  }

  if (asset.metadataFields) {
    ['asset_name', 'asset_height', 'asset_width', 'asset_mime', 'asset_updated_on', 'asset_size'].forEach(function(f) {
      if (asset.metadataFields[f] &&
                asset.metadataFields[f].length) {
        let key = f.replace(/asset_/, '');
        if (f=='asset_size') key = 'bytes';
        if (f=='asset_updated_on') key = 'date';
        ret[key] = asset.metadataFields[f][0];
      }
    });
    ['project', 'order', 'orderline'].forEach(function(f) {
      if (asset.metadataFields[f] &&
                asset.metadataFields[f].length) {
        const key = f+'s';
        const ids = asset.metadataFields[f].map((object) => (object.name.replace(/^[a-zA-Z]*_/, '')));
        ret[key] = ids.map((id) => ({id: id, label: null}));
      }
    });
    const uploadTypes = asset.metadataFields?.upload_type?.map(({name}) => name);
    if (uploadTypes) ret.uploadTypes = uploadTypes;
  }
  if (asset.derivatives) {
    if (asset.derivatives.thumb_png_200x200 &&
            asset.derivatives.thumb_png_200x200.assetStoreUrl) {
      ret['thumb'] = asset.derivatives.thumb_png_200x200.assetStoreUrl;
    }
    if (asset.derivatives.prev_png_800x600 &&
            asset.derivatives.prev_png_800x600.assetStoreUrl) {
      ret['preview'] = asset.derivatives.prev_png_800x600.assetStoreUrl;
    }
  }

  return ret;
}
