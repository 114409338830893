/* eslint-disable no-case-declarations */
import {Map} from 'immutable';

export default function reducer(state = {
  pageSize: 50,
  search: '',
  searchPageSize: 10,
  pages: new Map(),
  searchPages: new Map(),
  total: 0,
  searchTotal: 0,
  orderPageFetching: false,
  orderPageFetched: false,
  isFetching: false,
}, action) {
  switch (action.type) {
    case 'GET_ORDERS_REQUEST':
      if (!('page' in action.meta)) {
        return {
          ...state,
          orderPageFetching: ('order' in action.meta),
        };
      }

      return {
        ...state,
        pages: state.pages.set(
            action.meta.page,
            {
              ...state.pages.get(action.meta.page),
              status: 'FETCHING',
            },
        ),
      };

    case 'GET_ORDERS_COMPLETE':
      if (!('worksorders' in action.payload && 'page' in action.payload)) {
        return state;
      }

      const {worksorders, page} = action.payload;
      return {
        ...state,
        pages: state.pages.set(page, {
          status: 'FETCHED',
          worksorders,
        }),
        total: ('total' in action.payload) ? action.payload.total : 0,
        orderPageFetching: ('order' in action.meta) ? false : state.orderPageFetching,
        orderPageFetched: ('order' in action.meta),
      };

    case 'GET_ORDERS_FAILURE':
      if (!('page' in action.meta)) {
        return state;
      }

      return {
        ...state,
        pages: state.pages.set(action.meta.page, {
          status: 'FAILED',
          error: action.payload,
        }),
        orderPageFetching: false,
      };

    case 'GET_ORDERS_SEARCH_REQUEST':
      return {
        ...state,
        searchPages: state.searchPages.set(
            action.meta.page,
            {
              ...state.searchPages.get(action.meta.page),
              status: 'FETCHING',
            },
        ),
      };

    case 'GET_ORDERS_SEARCH_COMPLETE':
      if (action.meta.term !== state.search) {
        return state;
      }

      return {
        ...state,
        searchPages: state.searchPages.set(action.meta.page, {
          status: 'FETCHED',
          worksorders: action.payload.worksorders,
        }),
        searchTotal: ('total' in action.payload) ? action.payload.total : 0,
      };

    case 'GET_ORDERS_SEARCH_FAILURE':
      if (action.meta.term !== state.search) {
        return state;
      }

      return {
        ...state,
        searchPages: state.searchPages.set(action.meta.page, {
          status: 'FAILED',
          error: action.payload,
        }),
      };

    case 'Update order search term':
      return {
        ...state,
        search: action.payload,
        searchPages: state.searchPages.clear(),
      };

    case 'Change order filter':
      return {
        ...state,
        pages: state.pages.clear(),
        searchPages: state.searchPages.clear(),
      };

    default:
      return state;
  }
}
