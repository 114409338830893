export default function reducer(state = {
  fetchingSimple: false,
  fetchingAdvanced: false,
  fetchedSimple: false,
  fetchedAdvanced: false,
  failedAdvanced: false,
  isFetching: false,
  set: false,
}, action) {
  switch (action.type) {
    case 'GET_STATUSES_REQUEST':
      return {...state, fetchingSimple: true, fetchedSimple: false};

    case 'GET_STATUSES_COMPLETE':
      return {...state, fetchedSimple: true, fetchingSimple: false};

    case 'Filter fetch start':
      return {...state, fetchingAdvanced: true, fetchedAdvanced: false, failedAdvanced: false};
    case 'Filter fetch complete':
      return {...state, fetchingAdvanced: false, fetchedAdvanced: true};
    case 'Filter fetch complete with errors':
      return {...state, fetchingAdvanced: false, fetchedAdvanced: true, failedAdvanced: true};

    case 'Set advanced status filter':
    case 'Set status filter':
    case 'Set approver filter':
    case 'Set client filter':
      return {...state, set: true};

    default:
      return state;
  }
}
