/* eslint-disable no-case-declarations */
import {Map} from 'immutable';

export default function reducer(state = {
  pageSize: 50,
  search: '',
  searchPageSize: 10,
  total: 0,
  pages: new Map(),
  searchPages: new Map(),
  searchTotal: 0,
  linePageFetching: false,
  linePageFetched: false,
  isFetching: false,
  appliedFilter: null,
}, action) {
  switch (action.type) {
    case 'GET_LINES_REQUEST':
      if (!('page' in action.meta)) {
        return {
          ...state,
          linePageFetching: ('line' in action.meta),
        };
      }

      return {
        ...state,
        appliedFilter: action.meta.filters,
        pages: state.pages.set(
            action.meta.page,
            {
              ...state.pages.get(action.meta.page),
              status: 'FETCHING',
            },
        ),
      };

    case 'GET_LINES_COMPLETE':
      if (!('worksorderlines' in action.payload && 'page' in action.payload) ||
          action.meta.filters !== state.appliedFilter) {
        return state;
      }

      const {worksorderlines, page} = action.payload;

      return {
        ...state,
        pages: state.pages.set(page, {
          status: 'FETCHED',
          lines: worksorderlines,
        }),
        total: ('total' in action.payload) ? action.payload.total : 0,
        linePageFetching: ('line' in action.meta) ? false : state.linePageFetching,
        linePageFetched: ('line' in action.meta),
      };

    case 'GET_LINES_FAILURE':
      if (!('page' in action.meta)) {
        return state;
      }

      return {
        ...state,
        pages: state.pages.set(action.meta.page, {
          status: 'FAILED',
          error: action.payload,
        }),
        linePageFetching: false,
      };

    case 'GET_LINES_SEARCH_REQUEST':
      return {
        ...state,
        searchPages: state.searchPages.set(
            action.meta.page,
            {
              ...state.searchPages.get(action.meta.page),
              status: 'FETCHING',
            },
        ),
      };

    case 'GET_LINES_SEARCH_COMPLETE':
      if (action.meta.term !== state.search) {
        return state;
      }

      return {
        ...state,
        searchPages: state.searchPages.set(action.meta.page, {
          status: 'FETCHED',
          lines: action.payload.worksorderlines,
        }),
        searchTotal: ('total' in action.payload) ? action.payload.total : 0,
      };

    case 'GET_LINES_SEARCH_FAILURE':
      if (action.meta.term !== state.search) {
        return state;
      }

      return {
        ...state,
        searchPages: state.searchPages.set(action.meta.page, {
          status: 'FAILED',
          error: action.payload,
        }),
      };

    case 'Update line search term':
      return {
        ...state,
        search: action.payload,
        searchPages: state.searchPages.clear(),
      };

    case 'Change line filter':
      return {
        ...state,
        pages: state.pages.clear(),
        searchPages: state.searchPages.clear(),
        invalidate: state.invalidate + 1,
      };

    default:
      return state;
  }
}
