/* eslint-disable no-case-declarations */
import {Map} from 'immutable';

import {UPDATE_PROJECT_COMPLETE} from '../actions/projectActions';

function findProjectPage(pages, projectId) {
  let response = null;
  pages.findEntry((value, key) => {
    const pageIndex = findProjectPageIndex(value, projectId);
    if (pageIndex !== -1) {
      response = {
        pagesIndex: key,
        pageIndex,
      };

      return true;
    }

    return false;
  });

  return response;
}

function findProjectPageIndex(page, projectId) {
  return page.projects.findIndex((value) => value.id === projectId);
}

function updateProjectInPages(pages, project) {
  const pagePath = findProjectPage(pages, project.id);
  if (pagePath !== null) {
    pages.get(pagePath['pagesIndex'])['projects'][pagePath['pageIndex']] = project;
  }

  return pages;
}

export default function reducer(state = {
  pageSize: 50,
  search: '',
  searchPageSize: 10,
  pages: new Map(),
  searchPages: new Map(),
  total: 0,
  searchTotal: 0,
  projectPageFetching: false,
  projectPageFetched: false,
  isFetching: false,
}, action) {
  switch (action.type) {
    case 'GET_PROJECTS_REQUEST':
      if (!('page' in action.meta)) {
        return {
          ...state,
          projectPageFetching: ('project' in action.meta),
        };
      }

      return {
        ...state,
        pages: state.pages.set(
            action.meta.page,
            {
              ...state.pages.get(action.meta.page),
              status: 'FETCHING',
            },
        ),
      };

    case 'GET_PROJECTS_COMPLETE':
      if (!('projects' in action.payload && 'page' in action.payload)) {
        return state;
      }

      const {projects, page} = action.payload;

      return {
        ...state,
        pages: state.pages.set(page, {
          status: 'FETCHED',
          projects,
        }),
        total: ('total' in action.payload) ? action.payload.total : 0,
        projectPageFetching: ('project' in action.meta) ? false : state.projectPageFetching,
        projectPageFetched: ('project' in action.meta),
      };

    case 'GET_PROJECTS_FAILURE':
      if (!('page' in (action.meta || {}))) {
        return state;
      }

      return {
        ...state,
        pages: state.pages.set(action?.meta?.page, {
          status: 'FAILED',
          error: action.payload,
        }),
        projectPageFetching: false,
      };

    case UPDATE_PROJECT_COMPLETE:
      const pages = updateProjectInPages(state.pages, action.payload);

      return {
        ...state,
        pages,
      };
    case 'GET_PROJECTS_SEARCH_REQUEST':
      return {
        ...state,
        searchPages: state.searchPages.set(
            action.meta.page,
            {
              ...state.searchPages.get(action.meta.page),
              status: 'FETCHING',
            },
        ),
      };

    case 'GET_PROJECTS_SEARCH_COMPLETE':
      if (action.meta.term !== state.search) {
        return state;
      }

      return {
        ...state,
        searchPages: state.searchPages.set(action.meta.page, {
          status: 'FETCHED',
          projects: action.payload.projects,
        }),
        searchTotal: ('total' in action.payload) ? action.payload.total : 0,
      };

    case 'GET_PROJECTS_SEARCH_FAILURE':
      if (action.meta.term !== state.search) {
        return state;
      }

      return {
        ...state,
        searchPages: state.searchPages.set(action.meta.page, {
          status: 'FAILED',
          error: action.payload,
        }),
      };

    case 'Update project search term':
      return {
        ...state,
        search: action.payload,
        searchPages: state.searchPages.clear(),
      };

    default:
      return state;
  }
}
