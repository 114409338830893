/* eslint-disable no-case-declarations */
import {Map} from 'immutable';

export default function reducer(state = {
  total: 0,
  count: 0,
  pageSize: 30,
  approvals: [],
  approvalPageFetching: false,
  approvalPageFetched: false,
  pages: new Map(),
  search: '',
  isFetching: false,
  isAdvanced: false,
  fetchError: null,
  fetchComplete: false,
  scrollTop: 0,
  invalidate: 0,
  measureHeight: true,
  measuredHeight: null,
}, action) {
  switch (action.type) {
    case 'GET_APPROVALS_REQUEST':
      if (!('page' in action.meta) && !('approval' in action.meta)) {
        return state;
      }

      const pageNumber = action.meta.page;

      let newState = {
        ...state,
        isFetching: (
          ('newFetch' in action.meta && action.meta.newFetch) ||
            (!('page' in action.meta) && 'approval' in action.meta)
        ),
        fetchError: null,
        isAdvanced: action.meta.advanced,
        approvalPageFetching: ('approval' in action.meta),
        pages: state.pages.set(pageNumber, {...state.pages.get(pageNumber), status: 'FETCHING'}),
      };

      if (('newFetch' in action.meta) && action.meta.newFetch) {
        newState = {
          ...newState,
          measureHeight: true,
          measuredHeight: null,
          scrollTop: 0,
        };
      }


      return newState;

    case 'GET_APPROVALS_FAILURE':
      return {
        ...state,
        isFetching: false,
        approvalPageFetching: false,
        fetchError: action.payload,
      };

    case 'GET_APPROVALS_COMPLETE':
      return {
        ...state,
        isFetching: false,
        fetchComplete: true,
        approvalPageFetching: false,
        approvalPageFetched: ('approval' in action.meta),
        total: action.payload.total,
        pages: state.pages.set(action.payload.page, {
          status: 'FETCHED',
          approvals: action.payload.approvals,
        }),
      };

    case 'Receive approval':
    case 'Receive approval in current list':
      if (action.type !== 'Receive approval in current list' &&
          (!('id' in action.payload )|| !state.pages.some((page) => (
            page.status === 'FETCHED' && page.approvals.some((approval) => (
              action.payload.id === approval.id
            ))
          )))) {
        return state;
      }

      return {
        ...state,
        pages: state.pages.map((page) => (
            (page.status === 'FETCHED') ? {...page, status: 'INVALID'} : page
        )),
        invalidate: state.invalidate + 1,
      };

    case 'Change filter':
      return {
        ...state,
        pages: state.pages.clear(),
        invalidate: state.invalidate + 1,
      };

    case 'Update approval search term':
      return {
        ...state,
        search: action.payload,
        invalidate: state.invalidate + 1,
      };

    case 'Save scroll position':
      return {
        ...state,
        scrollTop: action.payload,
      };

    case 'Lock height':
      return {
        ...state,
        measureHeight: false,
        measuredHeight: action.payload,
      };

    default:
      return state;
  }
}
