import createFilterReducer from '../../../util/filterReducerGenerator';
import {GET_ASSET_KEYWORDS} from '../../../actions/assetfilterActions';

const itemTransformer = ({label, name}) => ({
  label,
  value: name,
});

const itemsTransformer = (itemToOptions, action) => action.payload.allowedValues.map(itemToOptions);

export default createFilterReducer({
  name: GET_ASSET_KEYWORDS,
  itemTransformer,
  itemsTransformer,
});
