export default function reducer(state = {
  status: [],
  client: [],
  group: [],
  agencies: [],
}, action) {
  switch (action.type) {
    case 'Change user filter':
      return {...state, ...action.payload};
    case 'Replace user filter':
      return {
        status: [],
        client: [],
        group: [],
        agencies: [],
        ...action.payload,
      };
    default:
      return state;
  }
}
