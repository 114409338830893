import {HttpRequest} from '../util/http';
import MultiPartish from 'multipartish';
import {doPPAuthenticatedApiCall} from '../util/Util';

class HttpBatcher {
  requests = [];

  addRequest(
      url,
      method,
      body,
      headers,
  ) {
    const request = new HttpRequest(url, method, body, headers);
    this.requests.push(request);
    return request.promise;
  }

  reset() {
    this.requests = [];
  }

  dispatch() {
    if (this.requests.length<1) {
      console.log('Attempt to dispatch empty batch request');
      return Promise.resolve();
    }

    const batch = new MultiPartish();

    for (let x=0; x < this.requests.length; x++) {
      const request = this.requests[x];

      if (!(request instanceof HttpRequest)) {
        throw new Error('Requests must be instances of HttpRequest');
      }

      request.name = 'request_'+x;
      batch.header('Content-Disposition', 'form-data; name="request_'+x+'"');
      batch.part(request.toString());
    }

    const _this = this;
    doPPAuthenticatedApiCall(
        '/batch',
        'POST',
        batch.get(),
        {
          'Content-Type': batch.contentType(),
          'Accept': 'multipart/mixed',
        },
    ).then(function(response) {
      _this.handleResponse(response);
    }).catch(function(response) {
      console.error('Batch request failed', response);
    });
  }

  handleResponse(response) {
    const contentType = response.headers.get('Content-Type');
    const boundary = contentType.replace(/^multipart\/mixed;\s*boundary=(.*)/i, '$1');
    const _this = this;

    response.text().then(function(body) {
      const parts = body.split('--'+boundary);
      parts.forEach((part) => _this.handlePart(part));
    });
  }

  handlePart(part) {
    part = part.trim();
    if (part.length<1 || part=='--') {
      return; // nothing to do
    }

    const bodyStart = part.indexOf('\r\n\r\n');
    const headerLines = part.substring(0, bodyStart).split(/\r\n/);
    const headers = {};
    headerLines.forEach((header) => {
      const parts = header.split(':');
      headers[parts[0].toLowerCase()] = parts[1];
    });
    const name = headers['content-disposition'].replace(/.*name="([^"]*)"/, '$1');
    const body = part.substring(bodyStart).trim();
    this.handleRequestPart(name, body);
  }

  handleRequestPart(name, part) {
    const bodyStart = part.indexOf('\r\n\r\n');
    let headerLines = [];
    let body = '';

    if (bodyStart < 0) {
      headerLines = part.split(/\r\n/);
    } else {
      headerLines = part.substring(0, bodyStart).split(/\r\n/);
      body = part.substring(bodyStart).trim();
    }

    const proto = headerLines[0];
    delete headerLines[0];
    const headers = {};

    headerLines.forEach((header) => {
      const parts = header.split(':');
      headers[parts[0].toLowerCase()] = parts[1];
    });

    const response = new Response(
        body,
        {
          status: proto.replace(/HTTP\/[0-9.]* ([0-9]{3}).*/, '$1'),
          statusText: proto.replace(/HTTP\/[0-9.]* [0-9]{3} (.*)/, '$1'),
          headers: headers,
        },
    );

    this.requests.forEach((request) => {
      if (request.name === name) {
        request.resolve(response);
      }
    });
  }
}

export default HttpBatcher;
