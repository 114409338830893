import approval from './approval';
import contactTypes from './contactTypesFilterReducer';
import project from './project';
import asset from './asset';
import user from './user';
import users from './usersFilterReducer';
import line from './line';
import order from './order';
import task from './task';
import {combineReducers} from 'redux';
import {createCategoryFilterReducer, default as createFilterReducer}
  from '../../util/filterReducerGenerator';
import {addPartnerIdItemTransformer} from '../../../util/partnerId';

export default combineReducers({
  approval, contactTypes, project, users, asset, user, line, order, task,
  category: createCategoryFilterReducer('GET_CATEGORIES'),
  lineProjects: createFilterReducer(addPartnerIdItemTransformer({
    name: 'GET_PROJECTS_FOR_LINES',
  }, (project) => (project.name))),
});
