const initialState = {
  fetchedWorkflowTemplate: false,
  fetchingWorkflowTemplate: false,
  fetchWorkflowTemplateError: null,
  workflowTemplate: null,

  fetchedWorkflowTemplates: false,
  fetchingWorkflowTemplates: false,
  fetchWorkflowTemplatesError: null,
  workflowTemplates: [],
};

export default function reducer(state = initialState, {payload, type}) {
  switch (type) {
    case 'GET_WORKFLOW_TEMPLATES_REQUEST':
      return {
        ...state,
        fetchedWorkflowTemplates: false,
        fetchingWorkflowTemplates: true,
        fetchWorkflowTemplatesError: null,
        workflowTemplates: [],
      };
    case 'GET_WORKFLOW_TEMPLATES_COMPLETE':
      return {
        ...state,
        fetchedWorkflowTemplates: true,
        fetchingWorkflowTemplates: false,
        workflowTemplates: payload.templates instanceof Array ? payload.templates : Object.values(payload.templates),
      };
    case 'CLEAR_WORKFLOW_TEMPLATE':
      return {
        ...state,
        fetchedWorkflowTemplate: false,
        fetchingWorkflowTemplate: false,
        fetchWorkflowTemplateError: null,
        workflowTemplate: null,
      };
    case 'GET_WORKFLOW_TEMPLATE_REQUEST':
      return {
        ...state,
        fetchedWorkflowTemplate: false,
        fetchingWorkflowTemplate: true,
        fetchWorkflowTemplateError: null,
        workflowTemplate: null,
      };
    case 'GET_WORKFLOW_TEMPLATE_COMPLETE':
      return {
        ...state,
        fetchedWorkflowTemplate: true,
        fetchingWorkflowTemplate: false,
        workflowTemplate: payload,
      };
    default:
      return state;
  }
}
