export default function reducer(state = {
  groups: [],
  selected: [],
  isFetching: false,
  fetchComplete: false,
  fetchError: null,
}, action) {
  switch (action.type) {
    case 'GET_ALL_GROUPS_REQUEST':
      return {
        ...state,
        isFetching: true,
        fetchComplete: false,
        fetchError: null,
      };

    case 'GET_ALL_GROUPS_COMPLETE':
      return {
        ...state,
        isFetching: false,
        fetchComplete: true,
        groups: action.payload.map((group) => {
          return {value: group.id, label: group.name};
        }),
      };

    case 'GET_ALL_GROUPS_FAILURE':
      return {...state, isFetching: false, fetchError: action.payload};

    case 'GET_USER_COMPLETE':
      return {
        ...state, selected: action.payload.groups.map((group) => {
          return {value: group.id, label: group.name};
        }),
      };

    default:
      return state;
  }
}
