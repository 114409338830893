export default function reducer(state = {
  statuses: [],
  selected: null,
  isFetching: false,
  fetchComplete: false,
  fetchError: null,
}, action) {
  switch (action.type) {
    case 'GET_USER_STATUSES_REQUEST':
      return {
        ...state,
        isFetching: true,
        fetchComplete: false,
        fetchError: null,
      };

    case 'GET_USER_STATUSES_COMPLETE':
      return {
        ...state,
        isFetching: false,
        fetchComplete: true,
        statuses: action.payload.filter((status) => (
          status.id !== 'UNVERIFIED'
        )).map((status) => {
          return {value: status.id, label: status.name};
        }),
      };

    case 'GET_USER_STATUSES_FAILURE':
      return {...state, isFetching: false, fetchError: action.payload};

    case 'GET_USER_COMPLETE':
      return {
        ...state,
        selected: {
          value: action.payload.status.id,
          label: action.payload.status.name,
        },
      };

    default:
      return state;
  }
}
