const initialState = {
  switchingUsers: false,
  isReallyLoggedIn: false,
  isLoggedIn: true,
  isLoggingIn: false,
  bearerToken: localStorage.getItem('bearerToken') || null,
  refreshToken: localStorage.getItem('refreshToken') || null,
  loginError: null,
  loginErrorResponse: null,
  isFetching: false,
  fetchComplete: false,
  fetchError: null,
  isVerifying: false,
  verificationComplete: false,
  verificationError: null,
  isResetting: false,
  resetComplete: false,
  resetError: null,
  isRequestingReset: false,
  requestResetComplete: false,
  requestResetError: null,
  returnUrl: null,
  token: null,
  permissionsUpdated: false,
  self: {
    fullName: null,
    displayName: null,
    email: null,
    username: null,
    displayFilters: [],
    displayViewNewFeatures: false,
    displayApprovalsManager: false,
    hasWorkflowClient: false,
    canUpdateAppStatus: false,
    permissions: [],
    websocket: null,
    domain: '',
    version: 'unknown',
  },
  stratum: {
    endpoint: null,
    token: null,
    tenant: null,
    fusion_tenant: null,
    isLoggedIn: false,
  },
  loginFormEmail: null,
};

export default function reducer(state = initialState, action) {
  let self;

  switch (action.type) {
    case 'LOGIN_REQUEST':
      return {
        ...state,
        isLoggingIn: true,
        isLoggedIn: false,
        isReallyLoggedIn: false,
        loginError: null,
        loginErrorResponse: null,
      };

    case 'LOGIN_FAILURE':
      return {
        ...state,
        loginError: action.payload.message,
        loginErrorResponse: action.payload.response,
      };

    case 'LOGIN_REFRESH_COMPLETE':
    case 'LOGIN_COMPLETE':
      // Save tokens to local storage
      if (action.payload.token) localStorage.setItem('bearerToken', action.payload.token);
      if (action.payload.refresh_token) localStorage.setItem('refreshToken', action.payload.refresh_token);

      // Update state
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: true,
        isReallyLoggedIn: true,
        loginFormEmail: null,
        bearerToken: action.payload.token,
        refreshToken: action.payload.refresh_token,
      };

    case 'LOGOUT_COMPLETE':
    case 'NOT_LOGGED_IN':
    case 'LOGIN_REFRESH_FAILURE':
      // Clear tokens from local storage
      localStorage.removeItem('bearerToken');
      localStorage.removeItem('refreshToken');

      // Update state
      return {
        ...state,
        bearerToken: null,
        refreshToken: null,
        isLoggingIn: false,
        isLoggedIn: false,
        isReallyLoggedIn: false,
      };

    case 'REQUIRE_LOGIN':
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: false,
        isReallyLoggedIn: false,
        returnUrl: action.payload.returnUrl,
      };

    case 'GET_SELF_REQUEST':
      return {
        ...state,
        isFetching: true,
        fetchComplete: false,
        fetchError: null,
      };

    case 'GET_SELF_FAILURE':
      return {
        ...state,
        isFetching: false,
        fetchError: action.payload,
      };

    case 'GET_SELF_COMPLETE':
      self = action.payload;
      self['displayFilters'] = getDisplayFilters(self,
          action.payload.displayFilters);

      return {
        ...state,
        isFetching: false,
        fetchComplete: true,
        isReallyLoggedIn: true,
        self,
      };

    case 'VERIFY_USER_REQUEST':
      return {
        ...state,
        isVerifying: true,
        verificationComplete: false,
      };

    case 'VERIFY_USER_FAILURE':
      return {
        ...state,
        isVerifying: false,
        verificationError: action.payload,
      };

    case 'VERIFY_USER_COMPLETE':
      self = action.payload;
      self['displayFilters'] = getDisplayFilters(self,
          action.payload.displayFilters);

      return {
        ...state,
        self,
        isVerifying: false,
        verificationComplete: true,
      };

    case 'RESET_PASSWORD_REQUEST':
      return {
        ...state,
        isResetting: true,
        resetComplete: false,
        resetError: null,
      };

    case 'RESET_PASSWORD_FAILURE':
      return {
        ...state,
        isResetting: false,
        resetComplete: false,
        resetError: action.payload,
      };

    case 'RESET_PASSWORD_COMPLETE':
      return {
        ...state,
        isResetting: false,
        resetComplete: true,
      };

    case 'UPDATE_SELF_COMPLETE':
      self = action.payload;

      return {
        ...state,
        self: {
          ...state.self,
          displayName: action.payload.displayName,
        },
      };

    case 'Click \'Forgotten your password?\' on login page':
      return {
        ...state,
        loginFormEmail: action.payload,
      };

    case 'REQUEST_PASSWORD_RESET_REQUEST':
      return {
        ...state,
        isRequestingReset: true,
        requestResetComplete: false,
        requestResetError: false,
      };

    case 'REQUEST_PASSWORD_RESET_FAILURE':
      return {
        ...state,
        isRequestingReset: false,
        requestResetError: action.payload,
      };

    case 'REQUEST_PASSWORD_RESET_COMPLETE':
      return {
        ...state,
        isRequestingReset: false,
        requestResetComplete: true,
        loginFormEmail: null,
      };

    case 'Permissions updated':
      return {
        ...state,
        permissionsUpdated: true,
      };

    case 'STRATUM_TOKEN':
      return {
        ...state,
        stratum: {
          ...state.stratum,
          ...action.payload,
          isLoggedIn: true,
        },
      };

    case 'IMPERSONATE_USER':
      return {
        ...state,
        switchingUsers: action?.payload,
      };

    default:
      return state;
  }
}

function getDisplayFilters(self, displayFilters) {
  const prependStatus =
      (Array.isArray(displayFilters) && displayFilters.length &&
          displayFilters[0].value === 'status') ?
          [] :
          [{value: 'status', label: 'Status'}];

  return prependStatus.
      concat((Array.isArray(displayFilters)) ?
          displayFilters :
          []);
}
