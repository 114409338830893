import current from './currentReducer';
import status from './statusReducer';
import createFilterReducer from '../../../util/filterReducerGenerator';
import {combineReducers} from 'redux';


const agenciesTransformer = (itemTransformer, {payload}) => {
  return payload && Array.isArray(payload.entities) ? payload.entities.map(itemTransformer) : [];
};

const agencyTransformer = ({id, name}) => ({
  label: name,
  value: id,
});


const filters = combineReducers({
  status: createFilterReducer({
    name: 'GET_USER_AUTHORISED_STATUSES',
    itemTransformer: (item) => ({value: item.id.toLowerCase(), label: item.name}),
  }),
  client: createFilterReducer({name: 'GET_USER_CLIENTS'}),
  group: createFilterReducer({name: 'GET_USER_GROUPS'}),
  agency: createFilterReducer({name: 'GET_USER_AGENCIES',
    itemTransformer: agencyTransformer,
    itemsTransformer: agenciesTransformer}),
});


export default combineReducers({
  filters, current, status,
});
