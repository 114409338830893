export default function reducer(state = {
  options: [],
  active: [],
}, action) {
  switch (action.type) {
    case 'Set log approval filter':
      return {
        ...state,
        active: action.payload,
      };
    default:
      return state;
  }
}
