export default function reducer(state = {
  fetching: false,
  fetched: false,
  failed: false,
}, action) {
  switch (action.type) {
    case 'Order filter fetch start':
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case 'Order filter fetch complete':
      return {
        ...state,
        fetching: false,
        fetched: true,
      };

    case 'Order filter fetch complete with errors':
      return {
        ...state,
        fetching: false,
        fetched: true,
        failed: true,
      };

    default:
      return state;
  }
}
