import createAsyncReducer from '../util/asyncReducerGenerator';

import {
  GET_CONTACT_TYPES_COMPLETE,
  GET_CONTACT_TYPES_FAILURE,
  GET_CONTACT_TYPES_REQUEST,
} from '../actions/contactTypeActions';

export default createAsyncReducer({
  typeComplete: GET_CONTACT_TYPES_COMPLETE,
  typeFailure: GET_CONTACT_TYPES_FAILURE,
  typeRequest: GET_CONTACT_TYPES_REQUEST,
});
