import {SET_ACTIVE_NAV} from '../actions/navActions';

const initialState = {
  filterAutoAdjustAllowed: true,
  activeNav: 'TASKS',
  nonWorkingDates: {dates: [], fetching: false},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'Lock filters from non-user input':
      return {...state, filterAutoAdjustAllowed: false};
    case SET_ACTIVE_NAV:
      return {...state, activeNav: action.payload.activeNav};
    case 'SET_ACTIVE_NAVIGATION_PAGE':
      return {...state, activeNav: action.payload};
    case 'GET_NON_WORKING_DATES_REQUEST':
      return {...state, nonWorkingDates: {fetching: true, dates: []}};
    case 'GET_NON_WORKING_DATES_COMPLETE':
      return {...state, nonWorkingDates: {fetching: false, dates: action.payload['non-working-dates']}};
    default:
      return state;
  }
}
