import {combineReducers} from 'redux';
import {reducer as notifications} from 'react-notification-system-redux';
import {createResponsiveStateReducer} from 'redux-responsive';
import {pick, isEmpty} from 'lodash';

import user from './userReducer';
import approval from './approvals';
import contactType from './contactTypeReducer';
import filters from './filters';
import focus from './focusReducer';
import misc from './miscReducer';
import preference from './preference';
import log from './log';
import users from './users';
import sidebar from './sidebarReducer';
import line from './lineReducer';
import lines from './linesReducer';
import order from './orderReducer';
import orders from './ordersReducer';
import project from './projectReducer';
import projects from './projectsReducer';
import assets from './assetsReducer';
import metadataFieldDefinitions from './assets/metadataFieldDefinitionsReducer';
import task from './taskReducer';
import tasks from './tasksReducer';
import createLines from './createLinesReducer';
import workflow from './workflowReducer';
import workflowTemplate from './workflowTemplateReducer';

const retainKeys = ['browser'];

const appReducer = combineReducers({
  user, users, approval, contactType, filters, focus, preference,
  notifications, misc, log, project, projects, sidebar, line, lines, workflow,
  createLines, assets, metadataFieldDefinitions, workflowTemplate,
  order, orders, task, tasks, browser: createResponsiveStateReducer(
      {
        extraSmall: 400,
        small: 720,
        medium: 1140,
        large: 1141,
        extraLarge: 1400,
      }, {
        extraFields: ({lessThan}) => ({
          mobile: lessThan.large,
        }),
      },
  ),
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_COMPLETE') {
    state = !isEmpty(retainKeys) ? pick(state, retainKeys) : undefined;
  }

  if (action.type === 'IMPERSONATE_USER' && action.payload === true) state = undefined;

  return appReducer(state, action);
};

export default rootReducer;
