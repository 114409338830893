export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const SET_SIDEBAR_EXPANDED = 'SET_SIDEBAR_EXPANDED';

export const closeSidebar = () => ({
  type: CLOSE_SIDEBAR,
});

export const openSidebar = () => ({
  type: OPEN_SIDEBAR,
});

export const setSidebarExpanded = (expanded) => ({
  type: SET_SIDEBAR_EXPANDED,
  payload: expanded,
});
