import {applyMiddleware, compose, createStore} from 'redux';
import {apiMiddleware} from 'redux-api-middleware';
import {responsiveStoreEnhancer} from 'redux-responsive';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

import reducer from './reducers';

function configureStore(initialState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ||
      compose;

  let middleware = [
    thunk,
    promise,
    apiMiddleware,
  ];

  if (process.env.NODE_ENV !== 'production') {
    middleware = [...middleware];
  }

  const store = createStore(
      reducer,
      initialState,
      composeEnhancers(responsiveStoreEnhancer, applyMiddleware(...middleware)),
  );

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers/index').default;
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

export default configureStore();
