/* eslint-disable no-case-declarations */
export default function reducer(state = {
  options: [],
  active: [],
  isFetching: false,
  fetchComplete: false,
  fetchError: null,
}, action) {
  switch (action.type) {
    case 'GET_LOGENTRY_TYPES_REQUEST':
      return {
        ...state,
        isFetching: true,
        fetchComplete: false,
        fetchError: null,
      };
    case 'GET_LOGENTRY_TYPES_FAILURE':
      return {...state, isFetching: false, fetchError: action.payload};
    case 'GET_LOGENTRY_TYPES_COMPLETE':
      const providedOptions = action.payload;
      const listOfOptions = [];

      providedOptions.forEach((option) => {
        listOfOptions.push({
          value: option.id.toLowerCase(),
          label: option.display_name,
          header: true,
        });

        option.children.forEach((option) => {
          listOfOptions.push({
            value: option.id.toLowerCase(),
            label: option.display_name,
          });
        });
      });

      return {
        ...state,
        isFetching: false,
        fetchComplete: true,
        options: listOfOptions,
      };

    case 'Set log type filter':
      return {
        ...state,
        active: action.payload,
      };
    default:
      return state;
  }
}
