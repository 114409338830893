/* eslint-disable no-case-declarations */
import {Map} from 'immutable';

export default function reducer(state = {
  total: 0,
  pageSize: 10,
  pages: new Map(),
  isFetching: false,
  fetchError: null,
  fetchComplete: false,
  search: '',
}, action) {
  switch (action.type) {
    case 'GET_USER_SEARCH_REQUEST':
      const pages = (action.meta.page === 1) ? state.pages.clear() : state.pages;

      return {
        ...state,
        isFetching: (action.meta.page === 1),
        fetchError: null,
        pages: pages.set(action.meta.page, {status: 'FETCHING'}),
      };

    case 'GET_USER_SEARCH_FAILURE':
      return {...state, isFetching: false, fetchError: action.payload};

    case 'GET_USER_SEARCH_COMPLETE':
      if (action.meta.term !== state.search) {
        return state;
      }

      return {
        ...state,
        isFetching: false,
        fetchComplete: true,
        total: action.payload.total,
        pages: state.pages.set(
            action.meta.page,
            {status: 'FETCHED', users: action.payload.users},
        ),
      };

    case 'Update user search term':
      return {
        ...state,
        search: action.payload,
        pages: state.pages.clear(),
      };

    default:
      return state;
  }
}
