export default function reducer(state = {
  status: null,
}, action) {
  if (action.type === 'Set simple filter') {
    return {
      ...state,
      status: action.payload,
    };
  }

  return state;
}
