import {dispatchPPApiCall} from '../util/apiCallGenerator';
import getPage from '../util/getPage';
import {getFilterUrl} from '../../util/Filter';

export function getOrders(pageNumber = 1) {
  return (dispatch, getState) => {
    const {pages, pageSize} = getState().orders;
    const current = getState().filters.order.current;

    return getPage(
        dispatch,
        'GET_ORDERS',
        'GET', '/worksorders',
        pages, pageSize, pageNumber, getFilterUrl(current).join('&'),
    );
  };
}

export function searchOrders(pageNumber = 1, refetch = false) {
  return (dispatch, getState) => {
    const {search, searchPages, searchPageSize} = getState().orders;
    const current = getState().filters.order.current;

    return getPage(
        dispatch,
        'GET_ORDERS_SEARCH',
        'GET', '/worksorders/_/search',
        searchPages, searchPageSize, pageNumber,
        `search=${search}&${getFilterUrl(current).join('&')}`, {term: search},
        refetch,
    );
  };
}


export function getOrder(orderId) {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'GET_ORDER',
        'GET', `/worksorders/${orderId}`,
        undefined, Array(3).fill({meta: {orderId}}),
    );
  };
}
export function getPageForOrder(order) {
  return (dispatch, getState) => {
    return dispatchPPApiCall(
        dispatch,
        'GET_ORDERS',
        'GET',
        `/worksorders?size=${getState().orders.pageSize}&order=${order}`,
        undefined, Array(3).fill({meta: {order}}),
    );
  };
}
