/* eslint-disable no-case-declarations */
import {Map} from 'immutable';

export default function reducer(state = {
  total: 0,
  pageSize: 10,
  pages: new Map(),
  isFetching: false,
  fetchError: null,
  fetchComplete: false,
  search: '',
}, action) {
  switch (action.type) {
    case 'GET_APPROVAL_SEARCH_REQUEST':
      let existingPages = state.pages;
      // request for page 1 overwrites existing pages
      if (action.meta.page === 1) {
        existingPages = existingPages.clear();
      }

      return {
        ...state,
        isFetching: (action.meta.page === 1),
        fetchError: null,
        pages: existingPages.set(action.meta.page, {status: 'FETCHING'}),
      };

    case 'GET_APPROVAL_SEARCH_FAILURE':
      return {...state, isFetching: false, fetchError: action.payload};

    case 'GET_APPROVAL_SEARCH_COMPLETE':
      if (action.meta.term !== state.search) {
        return state;
      }

      return {
        ...state,
        isFetching: false,
        fetchComplete: true,
        total: action.payload.total,
        pages: state.pages.set(action.meta.page, {status: 'FETCHED', approvals: action.payload.approvals}),
      };

    case 'Update approval search term':
      return {
        ...state,
        search: action.payload,
        pages: state.pages.clear(),
      };

    default:
      return state;
  }
}
