import {ApiError} from 'redux-api-middleware';

import {dispatchPPApiCall} from '../util/apiCallGenerator';
import {dispatchStratumApiCall} from '../util/stratumApiCallGenerator';
import {getAssets} from './assetActions';
import {getMetadataFieldDefinitions} from './metadataFieldDefinitionsActions';
import {getAllProjects} from './projectActions';

export function getAssetFilters() {
  return (dispatch, getState) => {
    const {filters} = getState().filters.asset;
    const fetcher = {
      'client': getClients,
      // 'keywords': getKeywords, // to remain disabled until MPP-1526 is resolved
      'project': getAllProjects,
      'uploadTypes': getUploadTypes,
    };

    return dispatch({type: 'Asset filter fetch start'}).then(() => {
      return Promise.all(
          Object.keys(fetcher)
              .filter((filter) => (!(filters[filter].isFetching || filters[filter].fetchComplete)))
              .map((filter) => dispatch(fetcher[filter]()))
              .map((p) => (p.catch((e) => e))),
      );
    }).then((e) => {
      let type = 'Asset filter fetch complete';
      if (e.some((response) => response.payload instanceof ApiError)) {
        type += ' with errors';
      }

      dispatch({type});
    });
  };
}

export function setAssetFilterOrderOptions(options) {
  return (dispatch) => {
    // Store the search term
    return dispatch({
      type: 'Asset filter order search options',
      payload: {
        options: options,
      },
    });
  };
}

export function setAssetFilterOrderSearchTerm(value) {
  return (dispatch) => {
    // Store the search term
    return dispatch({
      type: 'Asset filter order search term change',
      payload: {
        term: value,
      },
    });
  };
}

export function setAssetFilterLineOptions(options) {
  return (dispatch) => {
    // Store the search term
    return dispatch({
      type: 'Asset filter line search options',
      payload: {
        options: options,
      },
    });
  };
}

export function setAssetFilterLineSearchTerm(value, callback) {
  return (dispatch) => {
    // Store the search term
    return dispatch({
      type: 'Asset filter line search term change',
      payload: {
        term: value,
      },
    });
  };
}

function getClients() {
  return (dispatch) => (
    dispatchPPApiCall(
        dispatch,
        'GET_ASSET_CLIENTS',
        'GET',
        '/clients?all=1',
    )
  );
}

function getUploadTypes() {
  return (dispatch) => (
    dispatchPPApiCall(
        dispatch,
        'GET_ASSET_UPLOAD_TYPES',
        'GET',
        '/v2/uploadtypes?all=1',
    )
  );
}

export const GET_ASSET_KEYWORDS = 'GET_ASSET_KEYWORDS';
const GET_ASSET_KEYWORDS_COMPLETE = 'GET_ASSET_KEYWORDS_COMPLETE';
const GET_ASSET_KEYWORDS_FAILURE = 'GET_ASSET_KEYWORDS_FAILURE';
const GET_ASSET_KEYWORDS_REQUEST = 'GET_ASSET_KEYWORDS_REQUEST';

export function getKeywords() {
  return (dispatch) => dispatch({type: GET_ASSET_KEYWORDS_REQUEST})
      .then(() => dispatch(getMetadataFieldDefinitions()))
      .then(({payload}) => dispatch(getKeywordsComplete(payload[12])))
      .catch((error) => dispatch(getKeywordsFailure()));
}

const getKeywordsComplete = (definition) => ({
  type: GET_ASSET_KEYWORDS_COMPLETE,
  payload: definition,
});

const getKeywordsFailure = () => ({type: GET_ASSET_KEYWORDS_FAILURE});

export function setAssetFilters(filters, replace = false) {
  return (dispatch, getState) => {
    const state = getState();
    const stratumUserIds = state.filters.asset.filters.user.stratumIds;

    let promise = Promise.resolve();
    console.log(filters);

    ('user' in filters) && filters.user.forEach(function(userId) {
      if (stratumUserIds[userId]!==null && stratumUserIds[userId]>0) {
        // we already have this user ID, don't need to pull it again
        return;
      }
      promise = promise.then(
          () => {
            const uri = 'core/user/users?queryText=mhgfusion_'+userId+'&searchType=all&shortInfo=1&limit=100';

            return dispatchStratumApiCall(
                dispatch,
                'GET_STRATUMUSERS',
                'GET', uri,
            );
          },
      );
    });

    return promise
        .then(() => {
          return dispatch({
            type: (replace) ? 'Replace asset filter' : 'Change asset filter',
            payload: filters,
          }).then(() => {
            return dispatch(getAssets(1, true));
          });
        });
  };
}

export function updateSearchTerm(payload) {
  return {
    type: 'SET_ASSET_SEARCH_TERM',
    payload,
  };
}
