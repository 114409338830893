import {Map} from 'immutable';

export default function reducer(state = {
  isFetching: false,
  fetchComplete: false,
  fetchError: null,
  order: {},
  pageSize: 30,
  lineTotal: 0,
  lines: new Map(),
}, action) {
  switch (action.type) {
    case 'GET_ORDER_REQUEST':
      return {
        ...state,
        fetchError: null,
        isFetching: true,
      };

    case 'GET_ORDER_COMPLETE':
      return {
        ...state,
        isFetching: false,
        fetchComplete: true,
        order: action.payload,
        lines: state.lines.clear(),
      };

    case 'GET_ORDER_FAILURE':
      return {
        ...state,
        isFetching: false,
        fetchError: action.payload,
      };

    case 'GET_ORDER_LINES_REQUEST':
      if (!('meta' in action && 'page' in action.meta)) {
        return state;
      }

      return {
        ...state,
        lines: state.lines.set(
            action.meta.page,
            {status: 'FETCHING'},
        ),
      };

    case 'GET_ORDER_LINES_COMPLETE':
      if (!('meta' in action && 'page' in action.meta)) {
        return state;
      }

      return {
        ...state,
        lineTotal: action.payload.total,
        lines: state.lines.set(
            action.meta.page, {
              status: 'FETCHED',
              lines: action.payload.worksorderlines,
            },
        ),
      };

    case 'GET_ORDER_LINES_FAILURE':
      if (!('meta' in action && 'page' in action.meta)) {
        return state;
      }

      return {
        ...state,
        lines: state.lines.set(
            action.meta.page, {
              status: 'FAILED',
              error: action.payload,
            },
        ),
      };

    default:
      return state;
  }
}
