import {isUndefined} from 'lodash';

export class StorageItem {
  constructor(storage, key, initialValue) {
    this.key = key;
    this.storage = storage;

    if (!isUndefined(initialValue)) {
      this.set(initialValue);
    }
  }

  get() {
    if (typeof this.storage === 'object') {
      return JSON.parse(this.storage.getItem(this.key));
    }

    return null;
  }

  has() {
    return this.get() !== null;
  }

  set(value) {
    if (typeof this.storage === 'object') {
      this.storage.setItem(this.key, JSON.stringify(value));
    }
  }

  remove() {
    if (typeof this.storage === 'object') {
      this.storage.removeItem(this.key);
    }
  }
}

export class MemoryStorage {
  store = {};

  constructor(store) {
    if (!isUndefined(store)) {
      this.store = store;
    }
  }

  getItem(key) {
    return Object.prototype.hasOwnProperty.call(this.store, key) ? this.store[key] : null;
  }

  key(index) {
    const keys = Object.keys(this.store);

    if (index >= keys.length) {
      return null;
    }
    const key = keys[index + 1];

    return this.store[key];
  }

  setItem(key, value) {
    this.store[key] = value;
  }

  removeItem(key) {
    delete this.store[key];
  }

  clear() {
    this.store = {};
  }
}

export class LocalStorageItem extends StorageItem {
  constructor(key, initialValue) {
    super(localStorage, key, initialValue);
  }
}

export class SessionStorageItem extends StorageItem {
  constructor(key, initialValue) {
    super(sessionStorage, key, initialValue);
  }
}
