export default function reducer(state = {
  total: 0,
  badLines: [],
  lines: [],
  importId: null,
  processing: false,
  createError: null,
  imported: false,
  creatingSingle: false,
  createdSingle: false,
  createErrorSingle: null,
  updating: false,
  updated: false,
  updateError: null,
}, action) {
  switch (action.type) {
    case 'CREATE_LINES_REQUEST':
      return {...state, processing: true, createError: null};

    case 'CREATE_LINES_FAILURE':
      return {...state, processing: false, createError: action.payload};

    case 'CREATE_LINES_COMPLETE':
      return {
        ...state,
        ...action.payload,
        processing: false,
      };

    case 'COMPLETE_IMPORT_REQUEST':
      return {
        ...state,
        processing: true,
        imported: false,
      };

    case 'COMPLETE_IMPORT_COMPLETE':
      return {
        ...state,
        processing: false,
        imported: true,
      };

    case 'CREATE_LINE_REQUEST':
      return {
        ...state,
        creatingSingle: true,
        createdSingle: false,
      };

    case 'CREATE_LINE_COMPLETE':
      return {
        ...state,
        creatingSingle: false,
        createdSingle: true,
      };

    case 'CREATE_LINE_FAILURE':
      return {
        ...state,
        creatingSingle: false,
        createdSingle: false,
        createErrorSingle: action.payload,
      };

    case 'UPDATE_LINE_REQUEST':
      return {
        ...state,
        updating: true,
        updated: false,
        updateError: null,
      };

    case 'UPDATE_LINE_COMPLETE':
      return {
        ...state,
        updating: false,
        updated: true,
      };

    case 'UPDATE_LINE_FAILURE':
      return {
        ...state,
        updating: false,
        updateError: action.payload,
      };

    default:
      return state;
  }
}
