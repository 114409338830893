/* eslint-disable no-case-declarations */
import mime from 'mime-types';
export default function reducer(state = {
  isFetching: false,
  fetchComplete: true,
  options: [],
  fetchError: null,
}, action) {
  switch (action.type) {
    case 'GET_ASSETS_COMPLETE':
      let mimes = [];
      for (let o=0; o<state.options.length; o++) {
        mimes.push(state.options[o].value);
      }

      for (let i=0; i<action.payload.items.length; i++) {
        const item = action.payload.items[i];
        if (!item.metadataFields.asset_mime) {
          continue;
        }
        for (let m=0; m<item.metadataFields.asset_mime.length; m++) {
          const mime = item.metadataFields.asset_mime[m];
          if (mimes.indexOf(mime) === -1) {
            mimes.push(mime);
          }
        }
      }

      mimes = mimes.map((type) => ({value: type, label: mime.extension(type) || type}));

      return {
        ...state,
        options: mimes,
      };
    default:
      return state;
  }
}
