export default function reducer(state = {
  fetching: false,
  fetched: false,
  failed: false,
}, action) {
  switch (action.type) {
    case 'Asset filter fetch start':
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case 'Asset filter fetch complete':
      return {
        ...state,
        fetching: false,
        fetched: true,
      };

    case 'Asset filter fetch complete with errors':
      return {
        ...state,
        fetching: false,
        fetched: true,
        failed: true,
      };

    case 'Asset filter line search term change':
      if (action.payload.term == state.searchTerm) {
        return state; // no change!
      }
      return {
        ...state,
        fetched: false,
      };
    default:
      return state;
  }
}
