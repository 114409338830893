import {dispatchPPApiCall} from '../util/apiCallGenerator';
import {getFilterUrl} from '../../util/Filter';

export function getApprovals(page = 1, refetch = false) {
  return (dispatch, getState) => {
    if (!Number.isInteger(page)) {
      console.error('Can only get approvals for integer page number, received:', page);
      return false;
    }

    const pages = getState().approval.list.pages;

    if (!refetch && pages.has(page) && ['FETCHED', 'FETCHING'].includes(pages.get(page).status)) {
      // don't try and refetch
      return;
    }

    return dispatchGetApproval(
        dispatch,
        getFilterUrl(getState().filters.approval.current),
        getState().approval.list.pageSize,
        page, 1, refetch,
    );
  };
}

export function getPageForApproval(approval) {
  return (dispatch, getState) => {
    return dispatchGetPage(
        dispatch,
        getFilterUrl(getState().filters.approval.current),
        getState().approval.list.pageSize,
        approval,
    );
  };
}

export function searchApprovals(page = 1) {
  return (dispatch, getState) => {
    if (!Number.isInteger(page)) {
      console.error('Can only get approvals for integer page number, received:', page);
      return false;
    }

    const term = getState().approval.search.search;

    return dispatchSearchApproval(
        dispatch,
        term,
        getFilterUrl(getState().filters.approval.current),
        getState().approval.search.pageSize,
        page,
    );
  };
}

function dispatchSearchApproval(dispatch, term, filters, size, page = 1) {
  const filterUrl = filters.join('&');

  return dispatchPPApiCall(
      dispatch,
      'GET_APPROVAL_SEARCH',
      'GET',
      `/approvals/_/search?search=${term}&${filterUrl}&advanced=1&page=${page}&size=${size}`,
      undefined, [{meta: {page}}, {meta: {page, term}}, {meta: {page}}],
  );
}

function dispatchGetApproval(dispatch, filters, size, page = 1, advanced = 0, newFetch = false) {
  const filterUrl = filters.join('&');

  return dispatchPPApiCall(
      dispatch,
      'GET_APPROVALS',
      'GET', `/approvals?${filterUrl}&advanced=${advanced}&size=${size}&page=${page}`,
      undefined, [
        {meta: {page, advanced, newFetch}},
        {meta: {page, advanced, newFetch}},
        {meta: {page, advanced, newFetch}}],
  );
}

function dispatchGetPage(dispatch, filters, size, approval) {
  const filterUrl = filters.join('&');

  return dispatchPPApiCall(
      dispatch,
      'GET_APPROVALS',
      'GET',
      `/approvals?${filterUrl}&advanced=1&size=${size}&approval=${approval}`,
      undefined, [{meta: {approval}}, {meta: {approval}}, {meta: {approval}}],
  );
}

export function getApproval(id) {
  return (dispatch, getState) => {
    return dispatchPPApiCall(
        dispatch,
        'GET_APPROVAL',
        'GET', `/approvals/${id}?advanced=1`,
    );
  };
}
