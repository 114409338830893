import createAsyncReducer from '../../util/asyncReducerGenerator';
import {
  GET_USERS_ALL_COMPLETE,
  GET_USERS_ALL_FAILURE,
  GET_USERS_ALL_REQUEST,
} from '../../actions/userActions';

const payloadTransformer = ({payload}) => payload.users;

export default createAsyncReducer({
  payloadTransformer,
  typeComplete: GET_USERS_ALL_COMPLETE,
  typeFailure: GET_USERS_ALL_FAILURE,
  typeRequest: GET_USERS_ALL_REQUEST,
});
