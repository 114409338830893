import createFilterReducer, {createCategoryFilterReducer} from '../../../util/filterReducerGenerator';
import {combineReducers} from 'redux';
import current from './currentReducer';
import fetchStatus from './fetchStatusReducer';
import {addPartnerIdItemTransformer} from '../../../../util/partnerId';

const filters = combineReducers({
  category: createCategoryFilterReducer('GET_CATEGORIES'),
  client: createFilterReducer({name: 'GET_ALL_CLIENTS'}),
  project: createFilterReducer(addPartnerIdItemTransformer({
    name: 'GET_ALL_PROJECTS',
  }, (project) => (project.name))),
  status: createFilterReducer({name: 'GET_LINESTATUSES'}),
});

export default combineReducers({
  filters, current, fetchStatus,
});
