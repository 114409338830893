import {Map} from 'immutable';

import {
  UPDATE_PROJECT_COMPLETE,
  UPDATE_PROJECT_FAILURE,
  UPDATE_PROJECT_REQUEST,
} from '../actions/projectActions';

export default function reducer(state = {
  isFetching: false,
  fetchComplete: false,
  fetchError: null,
  project: null,
  pageSize: 30,
  lineTotal: 0,
  lines: new Map(),
  isCreating: false,
  createdId: null,
  createComplete: false,
  createError: null,
  isUpdating: false,
  updateComplete: false,
  updateError: null,
  updatedId: null,
}, action) {
  switch (action.type) {
    case 'GET_PROJECT_REQUEST':
      return {
        ...state,
        fetchComplete: false,
        fetchError: null,
        isFetching: true,
        lines: state.lines.clear(),
        current: parseInt(action.meta.projectId),
      };

    case 'GET_PROJECT_COMPLETE':
      return {
        ...state,
        isFetching: false,
        fetchComplete: true,
        project: action.payload,
      };

    case 'UPDATE_PROJECT_STATUS':
      return {
        ...state,
        project: {
          ...state.project,
          status: action.payload,
        },
      };

    case 'GET_PROJECT_FAILURE':
      return {
        ...state,
        isFetching: false,
        fetchError: action.payload,
      };

    case 'SET_CURRENT_PROJECT':
      return {
        ...state,
        current: parseInt(action.payload.id),
        project: action.payload,
      };
    case 'GET_PROJECT_LINES_REQUEST':
      if (!('meta' in action && 'page' in action.meta)) {
        return state;
      }

      return {
        ...state,
        lines: state.lines.set(
            action.meta.page,
            {status: 'FETCHING'},
        ),
      };

    case 'GET_PROJECT_LINES_COMPLETE':
      if (!('meta' in action && 'page' in action.meta)) {
        return state;
      }

      return {
        ...state,
        lineTotal: action.payload.total,
        lines: state.lines.set(
            action.meta.page, {
              status: 'FETCHED',
              lines: action.payload.worksorderlines,
            },
        ),
      };

    case 'GET_PROJECT_LINES_FAILURE':
      if (!('meta' in action && 'page' in action.meta)) {
        return state;
      }

      return {
        ...state,
        lines: state.lines.set(
            action.meta.page, {
              status: 'FAILED',
              error: action.payload,
            },
        ),
      };

    case 'CREATE_PROJECT_REQUEST':
      return {
        ...state,
        isCreating: true,
        createComplete: false,
        createError: null,
      };

    case 'CREATE_PROJECT_COMPLETE':
      return {
        ...state,
        isCreating: false,
        createComplete: true,
        createdId: action.payload.id,
      };

    case 'CREATE_PROJECT_FAILURE':
      return {
        ...state,
        isCreating: false,
        createError: action.payload,
      };

    case UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        isUpdating: true,
        updateComplete: false,
        updateError: null,
      };

    case UPDATE_PROJECT_COMPLETE:
      return {
        ...state,
        isUpdating: false,
        project: action.payload,
        updateComplete: true,
        updatedId: action.payload.id,
      };

    case UPDATE_PROJECT_FAILURE:
      return {
        ...state,
        isUpdating: false,
        updateError: action.payload,
      };

    default:
      return state;
  }
}
