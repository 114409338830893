import React from 'react';
import axios from 'axios';

interface State {
    messageIteration: number;
    messages: string[];
}

interface Props {}
class Announcements extends React.Component<Props, State> {
  constructor(props) {
    // Setup component
    super(props);
    this.state = {
      messageIteration: 0,
      messages: [],
    };
  }

  componentDidMount() {
    // Fetch messages
    axios.get<{messages: string[]}>('https://warning-messages.s3.eu-west-2.amazonaws.com/warning.json').then((response) => {
      // Got messages
      const messages = response.data?.messages || [];

      // Update state
      this.setState({messages});

      // Create message iterator
      if (messages.length > 1) {
        setInterval(() => {
          this.setState(({messageIteration, messages}) => {
            const nextIndex = messageIteration === messages.length-1 ? 0 : messageIteration+1;
            return {messageIteration: nextIndex};
          });
        }, 10000);
      } // Message switch delay, 10 seconds
    });
  }

  render() {
    // Do we have any messages? If not, do not show
    if (!this.state.messages.length) return null;

    // We have messages, return the banner
    return <div style={{fontSize: '18px', padding: '5px', backgroundColor: 'yellow', whiteSpace: 'nowrap', overflowX: 'hidden', textAlign: 'center'}}>
      {`  •  `}
      <span>{this.state.messages[this.state.messageIteration]}</span>
      {`  •  `}
    </div>;
  }
}

export default Announcements;
