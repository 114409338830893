/* eslint-disable no-case-declarations */
import {Map} from 'immutable';
import {normalizeAsset} from '../../util/asset';

export default function reducer(state = {
  pageSize: 50,
  search: '',
  pages: new Map(),
  total: 0,
  isReady: false,
  isFetching: false,
  key: false, // the current "set" of assets
  asset: {
    initialized: false,
    isFetching: false,
    isReady: false,
    isFailed: false,
    id: null,
    height: null,
    width: null,
    date: null,
    bytes: null,
    name: '',
    thumb: null,
    preview: null,
    download: false,
    projects: [],
    orders: [],
    orderlines: [],
  },
}, action) {
  switch (action.type) {
    case 'ASSET_PAGESIZE':
      return {
        ...state,
        pageSize: action.payload.pageSize,
      };
    case 'RESET_ASSET':
      return {
        ...state,
        asset: {
          initalized: false,
          isFetching: false,
          isReady: false,
        },
      };
    case 'GET_ASSET_REQUEST':
      return {
        ...state,
        asset: {
          initalized: true,
          isFetching: true,
          isReady: false,
        },
      };
    case 'GET_ASSET_COMPLETE':

      const values = normalizeAsset(action.payload);

      return {
        ...state,
        asset: {
          isFetching: false,
          isReady: true,
          id: action.payload.id,
          ...values,
        },
      };
    case 'Asset metadata label':
      if (action.payload.assetId!=state.asset.id) {
        return state;
      }

      const asset = {
        ...state.asset,
      };
      const type = action.payload.type+'s';
      if (asset[type]) {
        asset[type].forEach((object, idx) => {
          if (object.id==action.payload.id) {
            object.partnerId = action.payload.partnerId;
            object.label = action.payload.label;
            asset[type][idx].label = action.payload.label;
          }
        });
      }

      return {
        ...state,
        asset: asset,
      };
    case 'GET_ASSET_FAILURE':
      return {
        ...state,
        asset: {
          isFetching: false,
          isReady: false,
          isFailed: true,
        },
      };
    case 'GET_ASSETS_REQUEST':
      return {
        ...state,
        pages: state.pages.set(
            action.meta.pageNumber,
            {
              ...state.pages.get(action.meta.pageNumber),
              status: 'FETCHING',
            },
        ),
        isFetching: true,
      };
    case 'GET_ASSETS_COMPLETE':
      const {items} = action.payload;
      const {pageNumber} = action.meta;

      // if (search !== state.search) {
      //   return state;
      // }

      const assets = items.map(normalizeAsset);

      return {
        ...state,
        isFetching: false,
        isReady: true,
        pages: state.pages.set(
            pageNumber,
            {
              status: 'FETCHED',
              assets: assets,
            },
        ),
        total: action.payload.total_count,
      };
    case 'GET_ASSETS_UNSUCCESSFUL':
      return {
        ...state,
        pages: new Map(),
        isFetching: false,
        isReady: false,
      };

    case 'Change assets filter':
    case 'Replace assets filter':
      return {
        ...state,
        pages: new Map(),
        total: 0,
        isReady: false,
      };

    case 'SET_ASSET_SEARCH_TERM':
      return {
        ...state,
        search: action.payload,
        pages: state.pages.clear(),
      };

    default:
      return state;
  }
}


