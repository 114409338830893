import {get} from 'lodash';
import {LocalStorageItem} from './storageItem';
import store from '../redux/store';

const getCurrentUserIdFormStore = (store) => {
  return get(store.getState(), ['user', 'self', 'id'], null);
};

const canCurrentUserDeleteNote = (noteUserId) => {
  return noteUserId === getCurrentUserIdFormStore(store);
};

const sidebarExpanded = new LocalStorageItem('sidebar-expanded');

export {sidebarExpanded, canCurrentUserDeleteNote};
