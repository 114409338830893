export default function reducer(state = {
  client: [],
  status: [],
}, action) {
  switch (action.type) {
    case 'Change order filter':
      return {...state, ...action.payload};
    case 'Replace order filter':
      return {
        client: [],
        status: [],
        ...action.payload,
      };
    default:
      return state;
  }
}
