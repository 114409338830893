import React from 'react';
import PropTypes from 'prop-types';
import padPartnerId from '../../util/partnerId';

const PartnerId = ({id}) => {
  const partnerId = padPartnerId(id);
  return <span>{partnerId}</span>;
};

PartnerId.propTypes = {
  id: PropTypes.string.isRequired,
};

export default React.memo(PartnerId);
