import {dispatchPPApiCall} from './apiCallGenerator';

export default function getPage(dispatch, name, method, url,
    pages, pageSize, pageNumber = 1, queryStringAppend = '', meta = {}, refetch = false) {
  const combinedMeta = {meta: {...meta, page: pageNumber, filters: queryStringAppend}};

  if (!refetch && pages.has(pageNumber)) {
    const page = pages.get(pageNumber);
    if (page && 'status' in page && ['FETCHING', 'FETCHED'].includes(page.status)) {
      return Promise.resolve('Redundant request');
    }
  }

  return dispatchPPApiCall(
      dispatch,
      name,
      method, `${url}?page=${pageNumber}&size=${pageSize}&${queryStringAppend}`,
      undefined, Array(3).fill(combinedMeta),
  );
}
