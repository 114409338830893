import PropTypes from 'prop-types';
import React, {useEffect, useState, Suspense} from 'react';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';
import {get} from 'lodash';

const SiteDownPage = React.lazy(() => import('./auth/views/SiteDownPage'));
const LoginPage = React.lazy(() => import('./home/views/LoginPage'));
const ColoursPage = React.lazy(() => import('./main/views/ColoursPage'));
const TokenPage = React.lazy(() => import('./main/views/TokenPage'));
const ResetPasswordRequestPage = React.lazy(() => import('./auth/views/ResetPasswordRequestPage'));
const ResetPasswordPage = React.lazy(() => import('./auth/views/ResetPasswordPage'));
const UserDetailsEntryPage = React.lazy(() => import('./auth/views/UserDetailsEntryPage'));
const VerifyAccountPage = React.lazy(() => import('./auth/views/VerifyAccountPage'));
const RequireLogin = React.lazy(() => import('./auth/component/RequireLogin'));

import {sidebarExpanded} from 'Util/userPreferences';
import {setSidebarExpanded} from './redux/actions/sidebarActions';
import Loading from './generic/component/loading';

import {MaintenanceContext} from './maintenance-context';
import {doPPAuthenticatedApiCall} from './util/Util';

const Routes = ({history, setSidebarExpanded, isReallyLoggedIn}) => {
  const [checkedSiteDown, setCheckedSiteDown] = useState(false);
  const [siteDown, setSiteDown] = useState(false);

  useEffect(() => {
    const setSidebarExpandedState = () => {
      if (sidebarExpanded.has()) {
        setSidebarExpanded(sidebarExpanded.get());
      } else {
        setSidebarExpanded(false);
      }
    };
    setSidebarExpandedState();

    return history.listen(() => setSidebarExpandedState());
  }, []);

  const checkSiteDown = async () => {
    const response = await doPPAuthenticatedApiCall('/status', 'GET');
    const json = await response.json();

    if (get(json, 'status') !== 'normal') {
      setSiteDown(json);
    } else {
      setSiteDown(false);
    }

    setCheckedSiteDown(true);
  };

  useEffect(() => {
    checkSiteDown();

    return history.listen(() => checkSiteDown());
  }, []);

  // Show hide zendesk chat
  useEffect(() => {
    const chatIframeButton = document.querySelector('iframe#launcher');
    if (isReallyLoggedIn && chatIframeButton) {
      chatIframeButton.style.display = 'block';
    } else if (chatIframeButton) {
      chatIframeButton.style.display = 'none';
    }
  }, [isReallyLoggedIn]);

  if (!checkedSiteDown) {
    return (
      <div>
        <Loading />
      </div>
    );
  }


  return (
    <MaintenanceContext.Provider value={siteDown}>
      <Suspense fallback={<p>Loading...</p>}>
        <Switch>
          <Route path="/maintenance" component={SiteDownPage}/>
          <Route path="/login" component={LoginPage} />
          <Route path="/colours" component={ColoursPage} />
          <Route path="/token" component={TokenPage} />
          <Route path="/reset-password-request/:email?" component={ResetPasswordRequestPage} />
          <Route path="/reset-password/:token" component={ResetPasswordPage} />
          <Route path="/verifyemail/:email?/:date?/:hash?" component={UserDetailsEntryPage} />
          <Route path="/verify/:token" component={VerifyAccountPage} />
          <Route component={RequireLogin} />
        </Switch>
      </Suspense>
    </MaintenanceContext.Provider>
  );
};

Routes.propTypes = {
  history: PropTypes.shape({
    listen: PropTypes.func.isRequired,
  }).isRequired,
  setSidebarExpanded: PropTypes.func.isRequired,
  isReallyLoggedIn: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  setSidebarExpanded,
};

const mapStateToProps = (state) => ({
  isReallyLoggedIn: state.user.isReallyLoggedIn,
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Routes),
);
