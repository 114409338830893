import React from 'react';
import styles from './PartnerIdOption.scss';
import PartnerId from '../PartnerId';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PartnerIdOption = ({
  key = '',
  style = {},
  option,
  focusOption,
  selectValue,
  asValue = false,
}) => {
  let [partnerId, label] = option.label.split(': ');
  if (typeof label === 'undefined') {
    label = partnerId;
    partnerId = null;
  }

  return (
    <div
      key={key}
      className={classNames(styles.option, {
        [styles.value]: asValue,
      })}
      style={style}
      onMouseOver={typeof focusOption === 'function' ? () => focusOption(option) : null}
      onClick={typeof selectValue === 'function' ? () => selectValue(option) : null}
      title={label}
    >
      <div className={styles.label}><span>{label}</span></div>
      {partnerId && <div className={styles.partnerId}><PartnerId id={partnerId}/></div>}
    </div>
  );
};

PartnerIdOption.propTypes = {
  key: PropTypes.string,
  style: PropTypes.object,
  option: PropTypes.object,
  focusOption: PropTypes.func,
  selectValue: PropTypes.func,
  asValue: PropTypes.bool,
};

export default PartnerIdOption;
