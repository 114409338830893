import {combineReducers} from 'redux';

import current from './currentReducer';
import status from './statusReducer';
import createFilterReducer from '../../../util/filterReducerGenerator';

const usersReducer = createFilterReducer({
  name: 'GET_PROJECT_USERS',
  itemTransformer: ({id, displayName}) => ({
    label: displayName,
    value: id,
  }),
  itemsTransformer: (itemTransformer, action) => {
    if ('users' in action.payload && Array.isArray(action.payload.users)) {
      return action.payload.users.map(itemTransformer);
    }

    return [];
  },
});

const clientsReducer = createFilterReducer({
  name: 'GET_PROJECTCLIENTS',
  itemTransformer: ({id, name, prefix}) => ({
    label: name,
    value: id,
    prefix: prefix,
  }),
  itemsTransformer: (itemTransformer, action) => {
    if (Array.isArray(action.payload)) {
      return action.payload.map(itemTransformer);
    }
    return [];
  },
});

const filters = combineReducers({
  client: clientsReducer,
  contactTypes: createFilterReducer({name: 'GET_PROJECT_CONTACT_TYPES'}),
  status: createFilterReducer({name: 'GET_PROJECTSTATUSES'}),
  users: usersReducer,
});

export default combineReducers({
  filters, current, status,
});
