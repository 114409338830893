export default function reducer(state = {
  creating: false,
  created: false,
  createError: null,
}, action) {
  switch (action.type) {
    case 'CREATE_WORKFLOW_REQUEST':
      return {
        ...state,
        creating: true,
        created: false,
        createError: null,
      };

    case 'CREATE_WORKFLOW_COMPLETE':
      return {
        ...state,
        creating: false,
        created: true,
      };

    case 'CREATE_WORKFLOW_FAILURE':
      return {
        ...state,
        creating: false,
        createError: action.payload,
      };

    default:
      return state;
  }
}
