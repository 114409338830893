import {combineReducers} from 'redux';

import current from './currentReducer';
import fetchStatus from './fetchStatusReducer';
import {GET_ALL_TASKS_GROUPED_BY_NAME} from '../../../actions/taskfilterActions';
import {GET_ALL_WORKFLOWS_GROUPED_BY_TEMPLATE} from '../../../actions/workflowActions';
import createFilterReducer from '../../../util/filterReducerGenerator';
import {addPartnerIdItemTransformer} from '../../../../util/partnerId';

const taskItemsTransformer = (itemTransformer, {payload}) => payload.tasks.map(itemTransformer);
const taskItemTransformer = ({name}) => ({
  label: name,
  value: name,
});

const userItemsTransformer = (itemTransformer, {payload}) => payload.map(itemTransformer);

const workflowItemsTransformer = (itemTransformer, {payload}) => payload.workflows.map(itemTransformer);
const workflowItemTransformer = ({templateId, templateName}) => ({
  label: templateName,
  value: templateId,
});

const filters = combineReducers({
  approvalStatus: createFilterReducer({name: 'GET_TASK_APPROVAL_STATUSES'}),
  client: createFilterReducer({name: 'GET_ALL_CLIENTS'}),
  name: createFilterReducer({
    name: GET_ALL_TASKS_GROUPED_BY_NAME,
    itemsTransformer: taskItemsTransformer,
    itemTransformer: taskItemTransformer,
  }),
  project: createFilterReducer(addPartnerIdItemTransformer({
    name: 'GET_ALL_PROJECTS',
  }, (project) => (project.name))),
  status: createFilterReducer({name: 'GET_TASK_STATUSES'}),
  type: createFilterReducer({name: 'GET_TASK_TYPES'}),
  user: createFilterReducer({
    name: 'GET_TASK_USERS',
    itemsTransformer: userItemsTransformer,
  }),
  workflowTemplate: createFilterReducer({
    name: GET_ALL_WORKFLOWS_GROUPED_BY_TEMPLATE,
    itemsTransformer: workflowItemsTransformer,
    itemTransformer: workflowItemTransformer,
  }),
});

export default combineReducers({
  current,
  fetchStatus,
  filters,
});
