import {
  CLOSE_SIDEBAR,
  OPEN_SIDEBAR,
  SET_SIDEBAR_EXPANDED,
} from '../actions/sidebarActions';

const initialState = {
  expanded: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case CLOSE_SIDEBAR:
      return {
        ...state,
        expanded: false,
      };
    case OPEN_SIDEBAR:
      return {
        ...state,
        expanded: true,
      };
    case SET_SIDEBAR_EXPANDED:
      return {
        ...state,
        expanded: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
