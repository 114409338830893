import createAsyncReducer from './asyncReducerGenerator';
import {get} from 'lodash';

const defaultItemTransformer = ({id, name}) => ({
  label: name,
  value: id,
});

const defaultItemsTransformer = (itemTransformer, {payload}) => (
    Array.isArray(payload) ? payload.map(itemTransformer) : []
);

const createFilterReducer = ({
  name,
  itemTransformer = defaultItemTransformer,
  itemsTransformer = defaultItemsTransformer,
}) => createAsyncReducer({
  dataKey: 'options',
  payloadTransformer: itemsTransformer.bind(null, itemTransformer),
  typeComplete: `${name}_COMPLETE`,
  typeFailure: `${name}_FAILURE`,
  typeRequest: `${name}_REQUEST`,
});

const createCategoryFilterReducer = (name) => (state = {
  options: {'0': [], '1': [], '2': []},
  isFetching: {'0': false, '1': false, '2': false},
  fetchComplete: {'0': false, '1': false, '2': false},
  fetchError: {'0': null, '1': null, '2': null},
}, action) => {
  if (new RegExp(`${name}_DEPTH_\\d+`).test(action.type)) {
    const match = action.type.match(new RegExp(`${name}_DEPTH_(\\d+)_(.*)$`));

    if (match === null || !match.length > 1) {
      return state;
    }

    const depth = match[1];
    const type = match[2];

    switch (type) {
      case 'REQUEST':
        return {
          ...state,
          isFetching: {
            ...state.isFetching,
            [`${String(depth)}`]: true,
          },
          fetchComplete: {
            ...state.fetchComplete,
            [`${String(depth)}`]: false,
          },
          fetchError: {
            ...state.fetchError,
            [`${String(depth)}`]: null,
          },
        };
      case 'COMPLETE':
        return {
          ...state,
          isFetching: {
            ...state.isFetching,
            [`${String(depth)}`]: false,
          },
          fetchComplete: {
            ...state.fetchComplete,
            [`${String(depth)}`]: true,
          },
          options: {
            ...state.options,
            [`${String(depth)}`]: Array.isArray(action.payload) ?
              action.payload.map((category) => {
                return {
                  value: category.id,
                  label: get(category, 'path', category.name),
                };
              }) : [],
          },
        };
      case 'FAILURE':
        return {
          ...state,
          isFetching: {
            ...state.isFetching,
            [`${String(depth)}`]: false,
          },
          fetchError: {
            ...state.fetchError,
            [`${String(depth)}`]: action.payload,
          },
        };
    }
  }

  return state;
};

export {createFilterReducer as default, createCategoryFilterReducer};
