/* eslint-disable no-case-declarations */
export default function reducer(state = {
  total: 0,
  pageSize: 30,
  logs: [],
  pages: {},
  isFetching: false,
  fetchComplete: false,
  fetchError: null,
}, action) {
  switch (action.type) {
    case 'GET_LOGENTRIES_REQUEST':
      if (!('page' in action.meta)) {
        return state;
      }

      let existingPages = {};
      if (action.meta.page !== 1) {
        existingPages = state.pages;
      }

      return {
        ...state,
        isFetching: (action.meta.page === 1),
        fetchComplete: false,
        fetchError: null,
        pages: {
          ...existingPages,
          [`${action.meta.page}`]: {status: 'FETCHING'},
        },
      };

    case 'GET_LOGENTRIES_FAILURE':
      return {
        ...state,
        isFetching: false,
        fetchError: action.payload,
      };

    case 'GET_LOGENTRIES_COMPLETE':
      return {
        ...state,
        isFetching: false,
        fetchComplete: true,
        total: action.payload.total,
        pages: {
          ...state.pages,
          [`${action.payload.page}`]: {
            status: 'FETCHED',
            logentries: action.payload.logentries,
          },
        },
      };

    default:
      return state;
  }
}
