export default function reducer(state = {
  approval: [],
  client: [],
  type: [],
  user: [],
  dateFrom: null,
  dateTo: null,
}, action) {
  switch (action.type) {
    case 'Set log filter':
      return {
        ...state,
        ...action.payload,
      };

    case 'Replace log filter':
      return {
        approval: [],
        client: [],
        type: [],
        user: [],
        dateFrom: null,
        dateTo: null,
        ...action.payload,
      };

    default:
      return state;
  }
}
