const GET_ASSET_ORDERS_COMPLETE = 'GET_ASSET_ORDERS_COMPLETE';
const GET_ASSET_ORDERS_FAILURE = 'GET_ASSET_ORDERS_FAILURE';
const GET_ASSET_ORDERS_REQUEST = 'GET_ASSET_ORDERS_REQUEST';

export default function reducer(state = {
  isFetching: false,
  fetchComplete: false,
  options: null,
  fetchError: null,
  searchTerm: '',
}, action) {
  switch (action.type) {
    case 'Asset filter order search term change':
      if (action.payload.term == state.searchTerm) {
        return state; // no change!
      }
      return {
        ...state,
        searchTerm: action.payload.term,
        fetchComplete: false,
        isFetching: false,
      };
    case 'Asset filter order search options':
      return {
        ...state,
        isFetching: false,
        fetchComplete: true,
        options: action.payload.options,
      };
    case GET_ASSET_ORDERS_REQUEST:
      return {
        ...state,
        isFetching: true,
        fetchError: null,
      };
    case GET_ASSET_ORDERS_FAILURE:
      return {...state, isFetching: false, fetchError: action.payload};
    case GET_ASSET_ORDERS_COMPLETE:
      return {
        ...state,
        options: itemsTransformer(action),
        isFetching: false,
        fetchComplete: true,
      };
    default:
      return state;
  }
}

function itemTransformer(item) {
  return {value: item.id, label: item.works_order_code};
}
function itemsTransformer(action) {
  return action.payload.worksorders.map(itemTransformer);
}
