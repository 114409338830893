import {dispatchStratumApiCall} from '../util/stratumApiCallGenerator';
import {stringify as queryStringify} from 'query-string';
import {getProject} from './projectActions';
import {getLine} from './lineActions';
import {getOrder} from './orderActions';

export function setAssetPageSize(size) {
  return (dispatch, getState) => {
    return dispatch({
      type: 'ASSET_PAGESIZE',
      payload: {
        pageSize: size,
      },
    });
  };
}

export function getAssets(pageNumber = 1, refetch = false) {
  return (dispatch, getState) => {
    const {pages, pageSize, search} = getState().assets;
    const filters = getState().filters.asset.current;
    const stratumUserIds = getState().filters.asset.filters.user.stratumIds;

    if (!refetch && pages.has(pageNumber)) {
      const page = pages.get(pageNumber);
      if (page && 'status' in page && ['FETCHING', 'FETCHED'].includes(page.status)) {
        return Promise.resolve('Redundant request');
      }
    }

    const params = {
      offset: (pageNumber-1) * pageSize,
      limit: pageSize,
      deleted: 0,
    };

    if (search.length>0) {
      params.query = search;
    }
    let uri = 'core/asset/assets?'+queryStringify(params);

    let applyFilters = false;
    const queryFilters = [];

    if (filters.user && filters.user.length>0) {
      const values = [];
      for (let x=0; x<filters.user.length; x++) {
        const sId = parseInt(stratumUserIds[parseInt(filters.user[x])]);
        if (!isNaN(sId)) {
          values.push(sId);
        }
      }
      if (values.length>0) {
        applyFilters = true;
        const f = {
          fieldName: 'upload_user',
          values: values,
        };
        queryFilters.push(f);
      }
    }

    if (filters.client && filters.client.length>0) {
      applyFilters = true;
      const f = {
        fieldName: 'client',
        values: filters.client.map((x) => 'client_'+x),
      };
      queryFilters.push(f);
    }

    if (filters.project && filters.project.length>0) {
      applyFilters = true;
      const f = {
        fieldName: 'project',
        values: filters.project.map((x) => 'project_'+x),
      };
      queryFilters.push(f);
    }

    if (filters.order && filters.order.length>0) {
      applyFilters = true;
      const f = {
        fieldName: 'order',
        values: filters.order.map((x) => 'order_'+x),
      };
      queryFilters.push(f);
    }

    if (filters.line && filters.line.length>0) {
      applyFilters = true;
      const f = {
        fieldName: 'orderline',
        values: filters.line.map((x) => 'orderline_'+x),
      };
      queryFilters.push(f);
    }

    if (filters.task && filters.task.length > 0) {
      applyFilters = true;
      const f = {
        fieldName: 'task',
        values: filters.task.map((x) => 'task_' + x),
      };
      queryFilters.push(f);
    }

    if (filters.assetMime && filters.assetMime.length>0) {
      applyFilters = true;
      const f = {
        fieldName: 'asset_mime',
        values: filters.assetMime,
      };
      queryFilters.push(f);
    }

    if (applyFilters) {
      uri+='&permissionFilter=ALL';
      uri+='&filterCriteria='+JSON.stringify(queryFilters);
    }

    return dispatchStratumApiCall(
        dispatch,
        'GET_ASSETS',
        'GET', uri, null,
        {pageNumber, search},
    );
  };
}

export function resetAsset() {
  return (dispatch, getState) => {
    return dispatch({
      type: 'RESET_ASSET',
      payload: {
      },
    });
  };
}

export function getAsset(id) {
  return (dispatch, getState) => {
    const uri = 'core/asset/assets/'+id;

    return dispatchStratumApiCall(
        dispatch,
        'GET_ASSET',
        'GET', uri,
    ).then((data) => {
      const payload = data.payload;
      if (payload.metadataFields) {
        ['project', 'order', 'orderline'].forEach((key) => {
          if (payload.metadataFields[key]) {
            payload.metadataFields[key].forEach((object) => {
              dispatch(getAssetMetadataLabel(id, object.name));
            });
          }
        });
      }
    });
  };
}

/**
 * Grab a single project/order/line to obtain its label/name
 *
 * This function should be replaced with a generic GET /entityLabels?projects=1,2&orders=3,4&lines=5,6
 * call rather than doing individual lookups
 *
 * Replace in MPP-1525
 *
 * @param {integer} assetId - the ID of the asset
 * @param {string} name - type and ID in the metadata name field - e.g. project_1234
 * @return {Promise} dispatched lookup
 **/
export function getAssetMetadataLabel(assetId, name) {
  return (dispatch, getState) => {
    const type = name.replace(/_[0-9]*$/, '');
    const id = name.replace(/^[a-zA-Z]*_/, '');
    if (type=='project') {
      dispatch(getProject(id)).then((data) => {
        return dispatch({
          type: 'Asset metadata label',
          payload: {
            assetId: assetId,
            partnerId: data.payload.partnerId,
            id: data.payload.id,
            type: 'project',
            label: data.payload.name,
          },
        });
      });
    }
    if (type=='orderline') {
      dispatch(getLine(id)).then((data) => {
        let label = data.payload.item_reference;
        if (!label) {
          label = data.payload.description;
        }
        return dispatch({
          type: 'Asset metadata label',
          payload: {
            assetId: assetId,
            partnerId: data.payload.partnerId,
            id: data.payload.id,
            type: 'orderline',
            label: label,
          },
        });
      });
    }
    if (type=='order') {
      dispatch(getOrder(id)).then((data) => {
        return dispatch({
          type: 'Asset metadata label',
          payload: {
            assetId: assetId,
            id: data.payload.id,
            type: 'order',
            label: data.payload.works_order_code,
          },
        });
      });
    }
  };
}
