import {dispatchPPApiCall} from '../util/apiCallGenerator';

export function getAllClients() {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'GET_ALL_CLIENTS',
        'GET', '/clients?all=1',
    );
  };
}
