import {Map} from 'immutable';

export default function reducer(state = {
  isFetching: false,
  fetchComplete: false,
  fetchError: null,
  task: {},
  pageSize: 30,
  lineTotal: 0,
  lines: new Map(),
}, action) {
  switch (action.type) {
    case 'PATCH_TASK_REQUEST':
    case 'GET_TASK_REQUEST':
      return {
        ...state,
        fetchError: null,
        isFetching: true,
      };

    case 'PATCH_TASK_COMPLETE':
    case 'GET_TASK_COMPLETE':
      return {
        ...state,
        isFetching: false,
        fetchComplete: true,
        task: action.payload,
      };

    case 'PATCH_TASK_FAILURE':
    case 'GET_TASK_FAILURE':
      return {
        ...state,
        isFetching: false,
        fetchError: action.payload,
      };

    default:
      return state;
  }
}
