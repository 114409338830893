import deepEql from 'deep-eql';
import {ApiError} from 'redux-api-middleware';

import {getAllClients} from './clientActions';
import {getStatuses as getApprovalStatuses} from './filterActions';
import {getAllProjects} from './projectActions';
import {getAllTaskStatuses, getTasks, searchTasks} from './taskActions';
import {getAllWorkflowsGroupedByTemplate} from './workflowActions';
import {dispatchPPApiCall} from '../util/apiCallGenerator';
import {hasPermission} from '../../util/Util';

export function getTaskFilters() {
  return (dispatch, getState) => {
    const {filters} = getState().filters.task;
    let fetcher = {
      approvalStatus: () => getApprovalStatuses(1, 'GET_TASK_APPROVAL_STATUSES'),
      client: getAllClients,
      status: getAllTaskStatuses,
      type: getTypes,
    };

    const user = getState().user.self;
    const viewNewFeatures = user.displayViewNewFeatures;
    if (viewNewFeatures && hasPermission(user, 'read', 'Project')) {
      fetcher = {
        ...fetcher,
        project: getAllProjects,
      };
    }

    if (viewNewFeatures && hasPermission(user, 'read', 'Workflow')) {
      fetcher = {
        ...fetcher,
        workflowTemplate: getAllWorkflowsGroupedByTemplate,
      };
    }

    if (hasPermission(user, 'read', 'User')) {
      fetcher = {
        ...fetcher,
        user: getUsers,
      };
    }

    return dispatch({type: 'Task filter fetch start'})
        .then(() => Promise.all(
            Object.keys(fetcher)
                .filter((filter) => !(filters[filter].isFetching || filters[filter].fetchComplete))
                .map((filter) => dispatch(fetcher[filter]()))
                .map((p) => p.catch((e) => e)),
        ))
        .then((e) => {
          let type = 'Task filter fetch complete';
          if (e.some((response) => response.payload instanceof ApiError)) {
            type += ' with errors';
          }

          dispatch({type});
        });
  };
}

function getTypes() {
  return (dispatch) => dispatchPPApiCall(
      dispatch,
      'GET_TASK_TYPES',
      'GET',
      '/tasktypes',
  );
}

export const GET_ALL_TASKS_GROUPED_BY_NAME = 'GET_ALL_TASKS_GROUPED_BY_NAME';

export function getAllWorkflowTasksGroupedByName(workflowTemplateId) {
  return (dispatch) => dispatchPPApiCall(
      dispatch,
      GET_ALL_TASKS_GROUPED_BY_NAME,
      'GET',
      `/tasks?workflowTemplate=${workflowTemplateId}&groupByName=1&orderByName=ASC&size=100`,
  );
}

function getUsers() {
  return (dispatch) => dispatchPPApiCall(
      dispatch,
      'GET_TASK_USERS',
      'GET',
      '/users?legacy=1',
  );
}

export function setTaskFilters(filters, replace = false) {
  return (dispatch, getState) => {
    const fetchTasks = () => {
      const isSearching = getState().tasks.search.length;

      return Promise.all([
        (isSearching) ? dispatch(searchTasks(1, true)) : Promise.resolve(),
        dispatch(getTasks(1, true)),
      ]);
    };

    if (deepEql(getState().filters.task.current, filters)) {
      return fetchTasks();
    }

    return dispatch({
      type: (replace) ? 'Replace task filter' : 'Change task filter',
      payload: filters,
    }).then(fetchTasks);
  };
}
