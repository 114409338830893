import {dispatchStratumApiCall} from '../util/stratumApiCallGenerator';

export const GET_METADATA_FIELD_DEFINITIONS = 'GET_METADATA_FIELD_DEFINITIONS';

export function getMetadataFieldDefinitions() {
  return (dispatch) => (
    dispatchStratumApiCall(
        dispatch,
        GET_METADATA_FIELD_DEFINITIONS,
        'GET',
        'core/metadata/field/definitions',
    )
  );
}
