import all from './usersAllReducer';
import user from './userReducer';
import list from './listReducer';
import search from './searchReducer';

import {combineReducers} from 'redux';

export default combineReducers({
  all,
  list,
  search,
  user,
});
