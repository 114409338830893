import {Map} from 'immutable';

export default function reducer(state = {
  isFetching: false,
  fetchComplete: false,
  fetchError: null,
  users: [],
  pages: new Map(),
  total: 0,
  pageSize: 30,
}, action) {
  switch (action.type) {
    case 'GET_ALL_USERS_REQUEST':
      if (!('page' in action.meta)) {
        return state;
      }

      return {
        ...state,
        isFetching: (action.meta.page === 1),
        fetchError: null,
        pages: state.pages.set(action.meta.page, {
          ...state.pages.get(action.meta.page),
          status: 'FETCHING',
        }),
      };

    case 'GET_ALL_USERS_FAILURE':
      return {...state, isFetching: false, fetchError: action.payload};

    case 'GET_ALL_USERS_COMPLETE':
      if (!('page' in action.meta)) {
        return state;
      }

      return {
        ...state,
        isFetching: false,
        fetchComplete: true,
        total: action.payload.total,
        pages: state.pages.set(
            action.meta.page,
            {
              status: 'FETCHED',
              users: action.payload.users,
            },
        ),
      };

    default:
      return state;
  }
}
