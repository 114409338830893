export default function reducer(state = {
  focus: 'list',
}, action) {
  switch (action.type) {
    case 'Set status filter':
    case 'Set focus to list':
      return {...state, focus: 'list'};

    case 'Set advanced status filter':
    case 'Set approver filter':
    case 'Set client filter':
    case 'Set focus to filter':
      return {...state, focus: 'filter'};

    case 'Select approval':
      return {...state, focus: 'detail'};

    default:
      return state;
  }
}
