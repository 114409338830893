export default function reducer(state = {
  selected: [],
  clients: [],
  isFetching: false,
  fetchComplete: false,
  fetchError: null,
}, action) {
  switch (action.type) {
    case 'GET_ALL_CLIENTS_REQUEST':
      return {
        ...state,
        fetchComplete: false,
        fetchError: null,
        isFetching: true,
      };

    case 'GET_ALL_CLIENTS_COMPLETE':
      return {
        ...state,
        fetchComplete: true,
        isFetching: false,
        clients: action.payload.map((client) => {
          return {value: client.id, label: client.name};
        }),
      };

    case 'GET_ALL_CLIENTS_FAILURE':
      return {...state, fetchError: action.payload, isFetching: false};

    case 'GET_USER_COMPLETE':
      return {
        ...state, selected: action.payload.clients.map((client) => {
          return {value: client.id, label: client.name};
        }),
      };

    default:
      return state;
  }
}
