/* eslint-disable no-case-declarations */
import {Map} from 'immutable';
import {get} from 'lodash';

export default function reducer(state = {
  pageSize: 50,
  search: '',
  searchPageSize: 10,
  pages: new Map(),
  searchPages: new Map(),
  total: 0,
  searchTotal: 0,
  taskPageFetching: false,
  taskPageFetched: false,
  isFetching: false,
  appliedFilter: null,
}, action) {
  switch (action.type) {
    case 'GET_TASKS_REQUEST':
      if (!('page' in get(action, ['meta'], {}))) {
        return {
          ...state,
          taskPageFetching: ('task' in get(action, ['meta'], {})),
        };
      }

      return {
        ...state,
        appliedFilter: action.meta.filters,
        pages: state.pages.set(
            action.meta.page,
            {
              ...state.pages.get(action.meta.page),
              status: 'FETCHING',
            },
        ),
      };

    case 'GET_TASKS_COMPLETE':
      if (!('tasks' in action.payload && 'page' in action.payload) ||
          action.meta.filters !== state.appliedFilter) {
        return state;
      }

      let {tasks, page} = action.payload;
      // --- TEMPORARY FILTER --- In the future, this will be done in the back-end
      tasks = tasks.filter(({type: {id}}) => {
        return !['CLOUDFLOW_MANAGER', 'PROOFHQ_MANAGER', 'USER_SELECTION', 'FACADE'].includes(id);
      });

      // --- END OF TEMPORARY FILTER ---
      return {
        ...state,
        pages: state.pages.set(page, {
          status: 'FETCHED',
          tasks,
        }),
        total: ('total' in action.payload) ? action.payload.total : 0,
        taskPageFetching: ('task' in action.meta) ? false : state.taskPageFetching,
        taskPageFetched: ('task' in action.meta),
      };

    case 'GET_TASKS_FAILURE':
      if (!('page' in get(action, ['meta'], {}))) {
        return state;
      }

      return {
        ...state,
        pages: state.pages.set(action.meta.page, {
          status: 'FAILED',
          error: action.payload,
        }),
        taskPageFetching: false,
      };

    case 'GET_TASKS_SEARCH_REQUEST':
      return {
        ...state,
        searchPages: state.searchPages.set(
            action.meta.page,
            {
              ...state.searchPages.get(action.meta.page),
              status: 'FETCHING',
            },
        ),
      };

    case 'GET_TASKS_SEARCH_COMPLETE':
      if (action.meta.term !== state.search) {
        return state;
      }

      return {
        ...state,
        searchPages: state.searchPages.set(action.meta.page, {
          status: 'FETCHED',
          tasks: action.payload.tasks,
        }),
        searchTotal: ('total' in action.payload) ? action.payload.total : 0,
      };

    case 'GET_TASKS_SEARCH_FAILURE':
      if (action.meta?.term !== state.search) {
        return state;
      }

      return {
        ...state,
        searchPages: state.searchPages.set(action.meta.page, {
          status: 'FAILED',
          error: action.payload,
        }),
      };

    case 'Update task search term':
      return {
        ...state,
        search: action.payload,
        searchPages: state.searchPages.clear(),
      };

    case 'Change task filter':
      return {
        ...state,
        pages: state.pages.clear(),
        searchPages: state.searchPages.clear(),
      };

    case 'PATCH_TASK_COMPLETE':
      return {
        ...state,
        pages: state.pages.clear(),
        searchPages: state.searchPages.clear(),
      };

    default:
      return state;
  }
}
