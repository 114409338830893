export default function reducer(state = {
  isFetching: false,
  fetchComplete: false,
  fetchError: null,
  isSubmitting: false,
  submitComplete: false,
  submitError: null,
  isCreating: false,
  createComplete: false,
  createError: null,
  user: null,
}, action) {
  switch (action.type) {
    case 'GET_USER_REQUEST':
      return {...state, fetchComplete: false, isFetching: true, fetchError: null};

    case 'GET_USER_FAILURE':
      return {...state, isFetching: false, fetchError: action.payload};

    case 'GET_USER_COMPLETE':
      return {
        ...state,
        isFetching: false,
        fetchComplete: true,
        user: action.payload,
      };

    case 'UPDATE_USER_REQUEST':
    case 'UPDATE_SELF_REQUEST':
      return {
        ...state,
        isSubmitting: true,
        submitComplete: false,
        submitError: null,
      };

    case 'UPDATE_USER_FAILURE':
    case 'UPDATE_SELF_FAILURE':
      return {...state, isSubmitting: false, submitError: action.payload};

    case 'UPDATE_USER_COMPLETE':
    case 'UPDATE_SELF_COMPLETE':
      return {
        ...state,
        isSubmitting: false,
        submitComplete: true,
        user: state.user,
      };

    case 'CREATE_USER_REQUEST':
      return {
        ...state,
        isCreating: true,
        createComplete: false,
        createError: null,
      };

    case 'CREATE_USER_COMPLETE':
      return {...state, isCreating: false, createComplete: true};

    case 'CREATE_USER_FAILURE':
      return {...state, isCreating: false, createError: action.payload};

    default:
      return state;
  }
}
