import current from './currentReducer';
import fetchStatus from './fetchStatusReducer';
import createFilterReducer from '../../../util/filterReducerGenerator';
import {combineReducers} from 'redux';
import {addPartnerIdItemTransformer} from '../../../../util/partnerId';

const filters = combineReducers({
  project: createFilterReducer(addPartnerIdItemTransformer({
    name: 'GET_ORDER_PROJECTS',
  }, (project) => (project.name))),
  client: createFilterReducer({name: 'GET_ALL_CLIENTS'}),
  status: createFilterReducer({name: 'GET_ORDER_STATUSES'}),
});

export default combineReducers({
  filters, current, fetchStatus,
});
