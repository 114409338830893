import {
  GET_USERS_FILTER_COMPLETE,
  GET_USERS_FILTER_FAILURE,
  GET_USERS_FILTER_REQUEST,
} from '../../actions/filterActions';
import createAsyncReducer from '../../util/asyncReducerGenerator';

export default createAsyncReducer({
  typeComplete: GET_USERS_FILTER_COMPLETE,
  typeFailure: GET_USERS_FILTER_FAILURE,
  typeRequest: GET_USERS_FILTER_REQUEST,
});
