import {dispatchPPApiCall} from '../util/apiCallGenerator';
import {getFilterUrl} from '../../util/Filter';
import ability from '../../ability';

export function login(username, password) {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'LOGIN',
        'POST',
        '/login_check',
        {username, password},
        [],
        false,
    );
  };
}

export function loginRefresh(refreshToken) {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'LOGIN_REFRESH',
        'POST',
        '/token/refresh',
        {refresh_token: refreshToken},
        [],
        false,
    );
  };
}

export function logout() {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'LOGOUT',
        'GET',
        '/logout',
        undefined,
        [],
        true,
    ).then(() => {
      ability.update([]);
    });
  };
}

const GET_USERS_ALL = 'GET_USERS_ALL';

export const GET_USERS_ALL_COMPLETE = `${GET_USERS_ALL}_COMPLETE`;
export const GET_USERS_ALL_FAILURE = `${GET_USERS_ALL}_FAILURE`;
export const GET_USERS_ALL_REQUEST = `${GET_USERS_ALL}_REQUEST`;

export function getAllUsers() {
  return (dispatch) => dispatchPPApiCall(
      dispatch,
      GET_USERS_ALL,
      'GET',
      '/users',
  );
}

export function getUsers(columns, query) {
  return (dispatch) => {
    let queryString = 'limit=' + columns.join() + '&';
    Object.keys(query).forEach((col) => {
      queryString += col + '=' + query[col];
    });

    console.log(`GET /users?${queryString}`);

    return dispatchPPApiCall(
        dispatch,
        'GET_USERS',
        'GET', `/users?${queryString}`,
    );
  };
}

export function searchUsers(page = 1) {
  return (dispatch, getState) => {
    if (!Number.isInteger(page)) {
      console.error('Can only get users for integer page number, received:', page);
      return false;
    }

    const term = getState().users.search.search;

    const filters = getState().filters.user.current;
    const filterUrl = getFilterUrl(filters).join('&');

    const size = getState().users.search.pageSize;

    return dispatchPPApiCall(
        dispatch,
        'GET_USER_SEARCH',
        'GET',
        `/users?search=${term}&${filterUrl}&page=${page}&size=${size}`,
        undefined, [{meta: {page}}, {meta: {page, term}}, {meta: {page}}],
    );
  };
}

export function requireLogin(returnUrl) {
  return {
    type: 'REQUIRE_LOGIN',
    payload: {returnUrl},
  };
}

export function getSelf() {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'GET_SELF',
        'GET', `/users/self`,
    ).then((response) => {
      if (!(response?.payload && 'permissions' in response.payload)) {
        return;
      }

      const allCruds = response.payload.permissions.reduce((accumulator, value) => (
        accumulator.concat(('actions' in value) ? value.actions : [])
      ), []);

      const permissions = [
        ...response.payload.permissions,
        {subject: 'ANY', actions: ['create', 'read', 'update', 'delete'].filter((crud) => (
          allCruds.includes(crud)
        ))},
      ];

      ability.update(permissions);
      dispatch({type: 'Permissions updated'});
    });
  };
}

export function getAssetAccess() {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'GET_STRATUM',
        'GET', `/assetaccess`,
    ).then((response) => {
      if (!(response?.payload)) {
        console.log('No token returned when requesting asset access');
        return Promise.reject(new Error('No token returned when requesting asset access'));
      }

      return dispatch({type: 'STRATUM_TOKEN', payload: response.payload});
    });
  };
}

export function requestPasswordReset(email) {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'REQUEST_PASSWORD_RESET',
        'POST', '/users/reset/request', {email}, [], false,
    );
  };
}

export function resetPassword(token, first, second) {
  return (dispatch) => {
    const data = {
      confirmationToken: token,
      plainPassword: {first, second},
    };

    return dispatchPPApiCall(
        dispatch,
        'RESET_PASSWORD',
        'POST', '/users/reset/reset', data, [], false,
    );
  };
}

export function createUser(user) {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'CREATE_USER',
        'POST', '/users',
        user,
    );
  };
}

export function updateUser(user) {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'UPDATE_USER',
        'PATCH', `/v2/users/${user.id}`,
        user,
    );
  };
}

export function updateSelf(values) {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'UPDATE_SELF',
        'PATCH', '/users/self',
        values,
    );
  };
}

export function verifyUser(token, name, password) {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'VERIFY_USER',
        'POST', '/users/reset/verify',
        {token, displayName: name, password},
    );
  };
}

export function impersonateUser(redirect = true) {
  return {
    type: 'IMPERSONATE_USER',
    payload: redirect,
  };
}
