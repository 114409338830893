/* eslint-disable no-case-declarations */
export default function reducer(state = {
  isFetching: false,
  fetchComplete: false,
  options: [],
  stratumIds: [],
  fetchError: null,
}, action) {
  switch (action.type) {
    case 'GET_STRATUMUSERS_COMPLETE':
      const stratumIds = state.stratumIds;
      action.payload.items.forEach(function(item) {
        const userId = item.firstName.replace(/mhgfusion_/, '');
        stratumIds[userId] = item.id;
      });
      return {
        ...state,
        stratumIds: stratumIds,
      };
    case 'GET_ASSETUSERS_COMPLETE':
      const options = action.payload.users.map((user) => ({
        label: user.display_name,
        value: user.id,
      }));
      return {
        ...state,
        options: options,
        fetchComplete: true,
        isFetching: false,
      };
    case 'GET_ASSETS':
      // If we make new request for assets, let's reset stratum user IDs
      return {
        ...state,
        stratumIds: [],
      };
    case 'GET_ASSETS_COMPLETE':
      const {items} = action.payload;
      // These are MBS user ID's but they come from the stratum calls as they're attached to assets.
      const newStratumUserIDs = items.map(({metadataFields: {upload_user: {user_id: userID}}}) => userID);
      const combinedUserIDs = new Set([...state.stratumIds, ...newStratumUserIDs]);
      return {
        ...state,
        stratumIds: Array.from(combinedUserIDs),
      };
    default:
      return state;
  }
}
