import {Map} from 'immutable';

export default function reducer(state = {
  isFetching: false,
  fetchComplete: false,
  fetchError: null,
  line: {},
  pageSize: 30,
  taskTotal: 0,
  tasks: new Map(),
  fetchingFieldDefs: false,
  fetchedFieldDefs: false,
  fetchingWorkflowTemplates: false,
  fetchedWorkflowTemplates: false,
  fetchWorkflowTemplatesError: null,
  fieldDefs: [],
  workflowTemplates: [],
  workflowApprovalTasks: {},
}, action) {
  switch (action.type) {
    case 'GET_LINE_REQUEST':
      return {
        ...state,
        fetchError: null,
        fetchComplete: false,
        isFetching: true,
        tasks: state.tasks.clear(),
      };

    case 'GET_LINE_COMPLETE':
    case 'UPDATE_LINE_COMPLETE':
      return {
        ...state,
        isFetching: false,
        fetchComplete: true,
        line: action.payload,
      };

    case 'GET_LINE_FAILURE':
      return {
        ...state,
        isFetching: false,
        fetchError: action.payload,
      };

    case 'GET_LINE_APPROVALS_REQUEST':
      if (!('meta' in action && 'page' in action.meta)) {
        return state;
      }

      return {
        ...state,
        tasks: state.tasks.set(
            action.meta.page,
            {status: 'FETCHING'},
        ),
      };

    case 'GET_LINE_APPROVALS_COMPLETE':
      if (!('meta' in action && 'page' in action.meta)) {
        return state;
      }

      return {
        ...state,
        taskTotal: action.payload.total,
        tasks: state.tasks.set(
            action.meta.page, {
              status: 'FETCHED',
              tasks: action.payload.approvals,
            },
        ),
      };

    case 'GET_LINE_APPROVALS_FAILURE':
      if (!('meta' in action && 'page' in action.meta)) {
        return state;
      }

      return {
        ...state,
        tasks: state.tasks.set(
            action.meta.page, {
              status: 'FAILED',
              error: action.payload,
            },
        ),
      };

    case 'GET_LINE_APPROVALS_WORKFLOW_REQUEST':
      return {
        ...state,
        workflowApprovalTasks: {
          status: 'FETCHING',
        },
      };

    case 'GET_LINE_APPROVALS_WORKFLOW_FAILURE':
      return {
        ...state,
        workflowApprovalTasks: {
          status: 'FAILED',
          error: action.payload,
        },
      };

    case 'GET_LINE_APPROVALS_WORKFLOW_COMPLETE':
      return {
        ...state,
        workflowApprovalTasks: {
          status: 'FETCHED',
          tasks: action.payload,
        },
      };

    case 'GET_FIELD_DEFINITIONS_REQUEST':
      return {
        ...state,
        fetchingFieldDefs: true,
        fetchedFieldDefs: false,
      };

    case 'GET_FIELD_DEFINITIONS_COMPLETE':
      return {
        ...state,
        fieldDefs: action.payload,
        fetchedFieldDefs: true,
        fetchingFieldDefs: false,
      };

    case 'Reset line field definitions':
      return {
        ...state,
        fieldDefs: [],
      };

    case 'Reset line workflow templates':
      return {
        ...state,
        workflowTemplates: [],
      };

    default:
      return state;
  }
}
