import {Map as ImmutableMap} from 'immutable';
import {isObject} from 'lodash';

export function isImmutableMap(input) {
  return isObject(input) && input instanceof ImmutableMap;
}

export function isURL(input) {
  return isObject(input) && input instanceof URL;
}
