import {dispatchPPApiCall} from '../util/apiCallGenerator';
import {getFilterUrl} from '../../util/Filter';
import {ApiError} from 'redux-api-middleware';

export function getLogEntryFilters() {
  return (dispatch, getState) => {
    const {filters} = getState().log;
    const fetcher = {
      'type': getLogEntryTypes,
      'user': getLogEntryUsers,
      'client': getLogEntryClients,
    };

    return dispatch({type: 'Log filter fetch start'}).then(() => (
      Promise.all(
          Object.keys(fetcher).filter((filter) => (
            !(filters[filter].isFetching || filters[filter].fetchComplete)
          )).map((filter) => dispatch(fetcher[filter]())).map((p) => p.catch((e) => e)),
      )
    )).then((e) => {
      let type = 'Log filter fetch complete';
      if (e.some((response) => response.payload instanceof ApiError)) {
        type += ' with errors';
      }

      dispatch({type});
    });
  };
}

export function getLogEntryTypes() {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'GET_LOGENTRY_TYPES',
        'GET', '/logentrytypes',
    );
  };
}

export function getLogEntryUsers() {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'GET_LOGENTRY_USERS',
        'GET', '/users?legacy=1',
    );
  };
}

export function getLogEntryClients() {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'GET_LOGENTRY_CLIENTS',
        'GET', '/clients?all=1',
    );
  };
}

export function getLogEntries(page = 1, refetch = false) {
  return (dispatch, getState) => {
    const activeFilters = getState().log.active;
    const size = getState().log.log.pageSize;
    const pages = getState().log.log.pages;

    if (!refetch && `${page}` in pages &&
        ['FETCHED', 'FETCHING'].includes(pages[`${page}`].status)) {
      // don't try and refetch
      return;
    }

    return dispatchPPApiCall(
        dispatch,
        'GET_LOGENTRIES',
        'GET', `/logentries?${getFilterUrl(activeFilters).join('&')}`+
        `&size=${size}&page=${page}`,
        undefined, [{meta: {page}}, {meta: {page}}, {meta: {page}}],
    );
  };
}

export function setLogFilter(filter, replace = false) {
  return (dispatch, getState) => {
    const existingFilters = (replace) ? {} : getState().log.active;

    return dispatch({
      type: 'Replace log filter',
      payload: {
        ...existingFilters,
        ...filter,
      },
    }).then(() => {
      return dispatch(getLogEntries(1, true));
    });
  };
}
