import filters from './filters';
import log from './logReducer';
import config from './configReducer';
import active from './currentFilterReducer';

import {combineReducers} from 'redux';

export default combineReducers({
  filters, log, config, active,
});
