export default function reducer(state = {
  options: [],
  active: [],
  isFetching: false,
  fetchComplete: false,
  fetchError: null,
}, action) {
  switch (action.type) {
    case 'GET_LOGENTRY_CLIENTS_REQUEST':
      return {
        ...state,
        isFetching: true,
        fetchComplete: false,
        fetchError: null,
      };
    case 'GET_LOGENTRY_CLIENTS_FAILURE':
      return {...state, isFetching: false, fetchError: action.payload};
    case 'GET_LOGENTRY_CLIENTS_COMPLETE':
      return {
        ...state,
        isFetching: false,
        fetchComplete: true,
        options: action.payload.map((user) => {
          return {
            value: user.id,
            label: user.name,
          };
        }),
      };

    case 'Set log client filter':
      return {
        ...state,
        active: action.payload,
      };
    default:
      return state;
  }
}
