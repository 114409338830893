import {dispatchPPApiCall} from '../util/apiCallGenerator';

const GET_CONTACT_TYPES = 'GET_CONTACT_TYPES';

export const GET_CONTACT_TYPES_COMPLETE = `${GET_CONTACT_TYPES}_COMPLETE`;
export const GET_CONTACT_TYPES_FAILURE = `${GET_CONTACT_TYPES}_FAILURE`;
export const GET_CONTACT_TYPES_REQUEST = `${GET_CONTACT_TYPES}_REQUEST`;

export function getContactTypes() {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        GET_CONTACT_TYPES,
        'GET',
        '/contacttypes',
    );
  };
}
