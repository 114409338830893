import {memoize} from 'lodash';
import React from 'react';
import PartnerIdOption from '../main/component/partnerIdSelect/PartnerIdOption';
import PartnerIdValue from '../main/component/partnerIdSelect/PartnerIdValue';

const padPartnerId = (id) => {
  if (typeof id !== 'string') {
    return id;
  }

  return id.split('-').map((com, idx) => {
    switch (idx) {
      case 1:
        return com.padStart(3, '0');
      case 2:
        return com.padStart(4, '0');
      default:
        return com;
    }
  }).join('-');
};

const addPartnerIdToFilter = (filter) => ({
  ...filter,
  optionRenderer: PartnerIdOption,
  valueRenderer: ({label}) => {
    const Value = <PartnerIdValue label={label}/>;
    return Value;
  },
});

const addPartnerIdItemTransformer = (reduxSpec, label) => ({
  ...reduxSpec,
  itemTransformer: (item) => ({
    ...item,
    value: item.id,
    label: `${(typeof item.partnerId === 'string') ? padPartnerId(item.partnerId) + ': ' : ''}${label(item)}`,
  }),
});

const isPartnerId = function(id) {
  if (typeof id!=='string') {
    return false;
  }
  const match = id.match(/^[A-Z&]{3}-[0-9]+(?:-[0-9]+)?$/);
  return (match!==null && ('index' in match) && match.index==0) ? true : false;
};

const navigateToProject = function(history, project, urlFilters) {
  if (project.partnerId && isPartnerId(project.partnerId)) {
    const partnerId = padPartnerId(project.partnerId);
    history.push(`/projects/${partnerId}${urlFilters}`);
  } else {
    history.push(`/projects/${project.id}${urlFilters}`);
  }
};

const navigateToLine = function(history, line, urlFilters) {
  if (line.partnerId && isPartnerId(line.partnerId)) {
    const partnerId = padPartnerId(line.partnerId);
    history.push(`/lines/${partnerId}${urlFilters}`);
  } else {
    history.push(`/lines/${line.id}${urlFilters}`);
  }
};

const getProjectPath = function(project) {
  if (project.partnerId && isPartnerId(project.partnerId)) {
    return '/projects/'+padPartnerId(project.partnerId);
  } else {
    return '/projects/'+project.id;
  }
};

const getLinePath = function(line) {
  if (line.partnerId && isPartnerId(line.partnerId)) {
    return '/lines/'+padPartnerId(line.partnerId);
  } else {
    return '/lines/'+line.id;
  }
};

export default memoize(padPartnerId);
export {
  addPartnerIdToFilter,
  addPartnerIdItemTransformer,
  padPartnerId,
  isPartnerId,
  navigateToProject,
  getProjectPath,
  navigateToLine,
  getLinePath,
};
