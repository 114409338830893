export default function reducer(state = {
  set: false,
  fetched: false,
  fetching: false,
  failed: false,
  fetchCount: 0,
}, action) {
  switch (action.type) {
    case 'Log filter fetch start':
      return {...state, fetching: true, fetched: false, failed: false};
    case 'Log filter fetch complete':
      return {...state, fetched: true, fetching: false};
    case 'Log filter fetch complete with errors':
      return {...state, fetched: true, fetching: false, failed: true};

    case 'Set log filter':
    case 'Replace log filter':
      return {
        ...state,
        set: true,
      };
    default:
      return state;
  }
}
