export default function reducer(state = {
  approval: null,
  selected: null,
  isFetching: false,
  fetched: false,
  fetchError: null,
  invalid: false,
}, action) {
  switch (action.type) {
    case 'GET_APPROVAL_REQUEST':
      return {...state, isFetching: !state.invalid, fetched: false, fetchError: null};

    case 'GET_APPROVAL_FAILURE':
      return {
        ...state,
        isFetching: false,
        fetched: false,
        fetchError: action.payload,
      };

    case 'GET_APPROVAL_COMPLETE':
      return {
        ...state,
        isFetching: false,
        fetched: true,
        approval: action.payload,
        invalid: false,
      };

    case 'Leave approval':
      return {
        ...state,
        approval: null,
        selected: null,
        isFetching: false,
        fetchError: null,
      };

    case 'Select approval':
      return {
        ...state,
        selected: action.payload,
        fetched: false,
        isFetching: false,
      };

    case 'Receive approval':
      return ('id' in action.payload && action.payload.id === state.selected) ?
          {
            ...state,
            invalid: true,
          } : state;

    default:
      return state;
  }
}
