import React from 'react';
import PartnerId from '../PartnerId';
import styles from './PartnerIdValue.scss';
import PropTypes from 'prop-types';

const PartnerIdValue = ({label}) => {
  let [partnerId, restOfLabel] = label.split(': ');
  if (typeof restOfLabel === 'undefined') {
    restOfLabel = partnerId;
    partnerId = null;
  }

  return (
    <div title={restOfLabel}>
      <div className={styles.label}>{restOfLabel}</div>
      <div className={styles.partnerId}><PartnerId id={partnerId}/></div>
    </div>
  );
};

PartnerIdValue.propTypes ={
  label: PropTypes.string,
};

export default PartnerIdValue;
