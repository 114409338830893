export default function reducer(state = {
  status: [],
  approver: [],
  category0: [],
  category1: [],
  category2: [],
  client: [],
  project: [],
  dueFrom: null,
  dueTo: null,
  modifiedFrom: null,
  modifiedTo: null,
}, action) {
  switch (action.type) {
    case 'Change filter':
      return {...state, ...action.payload};
    case 'Replace filter':
      return {
        status: [],
        approver: [],
        category0: [],
        category1: [],
        category2: [],
        client: [],
        dueFrom: null,
        dueTo: null,
        modifiedFrom: null,
        modifiedTo: null,
        ...action.payload,
      };
    default:
      return state;
  }
}
