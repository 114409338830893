export default function reducer(state = {
  client: [],
  status: [],
  late: false,
}, action) {
  switch (action.type) {
    case 'Change project filter':
      return {...state, ...action.payload};
    case 'Replace project filter':
      return {
        client: [],
        status: [],
        late: false,
        ...action.payload,
      };
    default:
      return state;
  }
}
